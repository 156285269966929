import React, { useContext } from "react";
import powered_by from "../../assets/images/powered_by.svg";
import "./footer.scss";
import LanguageContext from "../Context/LanguageContext";
import { JAPANESE } from "../Constant/LangauageTranslate";

const Footer = () => {
  const lngCtx = useContext(LanguageContext);
  return (
    <div className='footer-block d-flex justify-content-between'>
      <div className='powered_by'>
        <p className='text-start'>
          <span className='me-1'>
            {lngCtx.localLanguage
              ? "Salary info is crowdsourced on"
              : JAPANESE.SalaryinfoiscrowdsourcedonOpensalary}
          </span>
          <a href='https://opensalary.jp/' target='_blank' rel='noreferrer'>
            <img src={powered_by} alt='powered-by' />
          </a>
        </p>
      </div>
      <div className='footer-notice'>
        <p>
          {lngCtx.localLanguage
            ? `All product names, logos, and brands are property of their respective
          owners. All company, product and service names used are for
          identification purposes only. Use of these names, logos, and brands
          does not imply endorsement.`
            : JAPANESE.FooterDescription}
        </p>
      </div>
    </div>
  );
};

export default Footer;
