import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from "./Components/redux/store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { FavouritesContextProvider } from "./Components/Context/FavouritesContext";
import { LanguageContextProvider } from "./Components/Context/LanguageContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <LanguageContextProvider>
      <FavouritesContextProvider>
        <BrowserRouter>
          <App />
          <Toaster />
        </BrowserRouter>
      </FavouritesContextProvider>
    </LanguageContextProvider>
  </Provider>
);


reportWebVitals();
