export const OPEN_SALARY_URL =
  "https://opensalary-customer-api-48qs6.ondigitalocean.app/v1";

export const catagoryOption = ["Industry", "Field", "Development"];

export const salaryRangeOption = [
  "$30,000 - 50,000",
  "$10,000 - 20,000",
  "$5,000 - 10,000",
];

export const locationOption = [
  "New York",
  "California",
  "Odessa",
  "Mumbai",
  "Banglore",
  "New York",
  "California",
  "Odessa",
  "Mumbai",
  "Banglore",
  "New York",
  "California",
  "Odessa",
  "Mumbai",
  "Banglore",
  "New York",
  "California",
  "Odessa",
  "Mumbai",
  "Banglore",
  "New York",
  "California",
  "Odessa",
  "Mumbai",
  "Banglore",
  "New York",
  "California",
  "Odessa",
  "Mumbai",
  "Banglore",
];

export const workTypeOption = ["Full-time", "Part-time", "Contract"];

export const remoteOptions = [
  { label: "On-Site", value: "On-Site" },
  { label: "Hybrid", value: "Hybrid Remote" },
  { label: "Full Remote", value: "Full Remote" },
];
export const remoteOptionsJapanese = [
  { label: "現場で", value: "On-Site" },
  { label: "ハイブリッド", value: "Hybrid Remote" },
  { label: "フルリモート", value: "Full Remote" },
];

export const jobCategoryOptions = [
  "Software Engineer",
  "Engineering Manager",
  "Product Manager",
  "Data Scientist",
  "Data Analyst",
  "Designer",
  "Pre-sales",
  "Support",
  "Sales",
  "Marketing/PR",
  "Back Office",
  "Other",
];

export const pageSize = "24";

export const formatNumber = (val) => {
  return val?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const languageDropdownOpt = [
  { label: "Japanese Required", value: "japanese" },
  { label: "English Required", value: "english" },
  { label: "Bilingual (J/E) Required", value: "bilingual" },
];
export const languageDropdownOptJapanse = [
  { label: "日本語必須", value: "japanese" },
  { label: "英語必須", value: "english" },
  { label: "バイリンガル（日英）必須", value: "bilingual" },
];

export const companyStageOptions = [
  { label: "Startup", value: "Startup" },
  { label: "Enterprise", value: "Enterprise" },
];
export const companyStageJapaneseOptions = [
  { label: "スタートアップ", value: "Startup" },
  { label: "大手企業", value: "Enterprise" },
];
