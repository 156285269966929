import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Button,
  Form,
  FormGroup,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import './searchBar.scss';
import Select from 'react-select';
import searchbar_search from '../../assets/images/searchbar_search.svg';

import {
  languageDropdownOpt,
  languageDropdownOptJapanse,
  remoteOptions,
  remoteOptionsJapanese,
} from '../Constant/constant';
import { useDispatch, useSelector } from 'react-redux';
import {
  focusValue,
  jobRoles,
  removeCompanydetails,
  searchedText,
} from '../redux/actions';
import { useLocation, useNavigate } from 'react-router';
import { Controller, useForm } from 'react-hook-form';

import { JAPANESE } from '../Constant/LangauageTranslate';
import LanguageContext from '../Context/LanguageContext';

const SearchBar = ({ resetPage, setResetPage }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Get individual query string parameters
  const nameParam = queryParams.get('name');
  const roleParam = queryParams.get('role');
  const remotePolicyParam = queryParams.get('remote_policy');
  const requiredLanguagesParam = queryParams.get('required_languages');
  const dispatch = useDispatch();
  const { localLanguage, setLocalLanguage } = useContext(LanguageContext);
  const lang = useSelector((state) => state.search.selectedLang);
  const locationsArr = useSelector((state) => state.search.locations);
  const searchedValue = useSelector((state) => state.search.searchedValue);

  const roles = useSelector((state) => state.search.roles);

  const navigate = useNavigate();

  const [optIsLoading, setOptIsLoading] = useState(false);
  const [locOptions, setLocOptions] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedLangReq, setSelectedLangReq] = useState([]);
  const [langAdded, setLangAdded] = useState('');
  const languageDropdown = localLanguage
    ? languageDropdownOpt
    : languageDropdownOptJapanse;
  const locations = locationsArr?.map((x) => ({ value: x, label: x }));

  const {
    control,
    register,
    watch,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      search: '',
      role: '',
      required_languages: [],
      remote_policy: '',
    },
  });
  const isJobDetailPage = location.pathname.includes('/jobdetail');

  useEffect(() => {
    // Set the initial values of the fields
    if (isJobDetailPage)
      setValue('search', searchedValue?.search ? searchedValue?.search : '');
    else setValue('search', nameParam || '');
  }, [nameParam]);
  useEffect(() => {
    if (isJobDetailPage)
      setValue(
        'role',
        searchedValue?.role
          ? selectOptionConverter([searchedValue?.role])[0]
          : ''
      );
    else
      setValue('role', roleParam ? selectOptionConverter([roleParam])[0] : '');
  }, [roleParam]);
  useEffect(() => {
    if (isJobDetailPage)
      setValue(
        'remote_policy',
        searchedValue?.remote_policy
          ? selectOptionConverter([searchedValue?.remote_policy])[0]
          : ''
      );
    else
      setValue(
        'remote_policy',
        remotePolicyParam ? selectOptionConverter([remotePolicyParam])[0] : ''
      );
  }, [remotePolicyParam]);

  useEffect(() => {
    if (isJobDetailPage)
      searchedValue?.required_languages &&
        setValue(
          'required_languages',
          setLangAdded(searchedValue?.required_languages)
        );
    else
      setValue(
        'required_languages',
        requiredLanguagesParam ? setLangAdded(requiredLanguagesParam) : ''
      );
  }, [
    isJobDetailPage,
    requiredLanguagesParam,
    searchedValue?.required_languages,
    setValue,
  ]);

  const selectOptionConverter = (Options) => {
    return Options?.map((x) =>
      x?.value && x?.label
        ? {
            value: x?.value,
            label: x?.label,
          }
        : {
            value: x,
            label: x,
          }
    );
  };
  useEffect(() => {
    dispatch(focusValue([]));
    setLangAdded('');
    const getdefaultVal = getValues();
  }, []);

  useEffect(() => {
    if (lang !== '') {
      dispatch(jobRoles(lang));

      const updatedLangOpt = [];
      languageDropdownOpt?.forEach((x, i) => {
        updatedLangOpt.push({ ...x, isSelected: true });
      });
      setSelectedLangReq(updatedLangOpt);
    }
  }, [lang]);

  useEffect(() => {
    setLocOptions(locations.slice(0, 5));
  }, [locationsArr]);

  const jobCategory = roles?.map((x) => ({ value: x, label: x }));
  const remoteOption = (localLanguage
    ? remoteOptions
    : remoteOptionsJapanese
  ).map((x) => ({
    value: x.value,
    label: x.label,
  }));

  //mobile layout finder
  const [isMobile, setIsMobile] = useState(false);

  // create an event listener
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    window.addEventListener('resize', handleResize);
  }, []);

  const submitHandler = async (data) => {
    dispatch(removeCompanydetails(null));

    if (requiredLanguagesParam) {
      setLangAdded(requiredLanguagesParam);
    }
    navigate({
      pathname: '/jobsearch',
      search:
        `?${data.search !== '' ? `name=${data?.search}` : ''}` +
        (!data.role ? '' : `&role=${data?.role?.value}`) +
        (!data.remote_policy
          ? ''
          : `&remote_policy=${data?.remote_policy?.value}`) +
        (langAdded ? `&required_languages=${langAdded}` : ''),
    });

    const info = {
      search: !data.search ? '' : data.search,
      role: !data.role ? '' : data.role.value,
      required_languages: langAdded,
      remote_policy: !data.remote_policy ? '' : data.remote_policy.value,
    };
    dispatch(searchedText(info));
  };

  const addOptions = () => {
    if (locOptions.length < 5) {
      setOptIsLoading(false);
    } else {
      setOptIsLoading(true);
    }
    setTimeout(() => {
      setPage(page + 1);
      setOptIsLoading(false);
      setLocOptions(locations.slice(0, locOptions.length + 5));
    }, 1000);
  };

  const jobCatVal = watch('role');

  useEffect(() => {
    if (!jobCatVal || jobCatVal === '') {
      dispatch(focusValue([]));
    } else {
      let focusVal;
      roles.filter((x) => {
        if (x.name === jobCatVal.value) {
          focusVal = x.focuses;
        }
      });
      dispatch(focusValue(focusVal));
    }
  }, [jobCatVal]);

  const langReqChange = (e, i) => {
    setValue(
      'required_languages',
      e.target.checked ? [e.target.defaultValue] : []
    );
    setLangAdded(
      e.target.checked
        ? languageDropdown.find((el) => el.value === e.target.value).value
        : ''
    );
  };

  const resetHandler = () => {
    setValue('search', '');
    setValue('role', '');
    setValue('required_languages', []);
    setValue('remote_policy', '');
    navigate({
      pathname: '/jobsearch',
    });

    const info = {
      search: '',
      role: '',
      required_languages: '',
      remote_policy: '',
    };

    dispatch(searchedText(info));
    setLangAdded('');
    setTimeout(() => {
      setResetPage(true);
    }, [1000]);
  };

  const ref = useRef();

  return (
    <>
      <Form
        className='cstm-serach-form mb-5'
        onSubmit={handleSubmit(submitHandler)}
      >
        <div className='search-bar'>
          <div className='flex-grow-1 search-input with-icon'>
            <div className='input-icon'>
              <img src={searchbar_search} alt='searchbar_search' />
            </div>
            <Controller
              id='search'
              name='search'
              control={control}
              render={({ field }) => (
                <Form.Group className='' controlId='searchtext'>
                  <Form.Control
                    type='text'
                    placeholder={
                      localLanguage ? 'Search Jobs...' : JAPANESE.SearchJobs
                    }
                    {...field}
                  />
                </Form.Group>
              )}
            />
          </div>

          <div
            className='search-input search-width-job-category'
            style={{ zIndex: '100' }}
          >
            <Controller
              id='role'
              name='role'
              control={control}
              render={({ field }) => (
                <Select
                  options={jobCategory}
                  placeholder={
                    localLanguage ? 'Job Category' : JAPANESE.JobCategory
                  }
                  classNamePrefix='react-select'
                  {...field}
                  isClearable
                />
              )}
            />
          </div>

          <div className='search-input search-width-job-language d-flex justify-content-between align-content-center '>
            <div className='d-flex align-items-center flex-row justify-content-around'>
              <OverlayTrigger
                rootClose
                trigger='click'
                key='bottom'
                placement='bottom'
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      <div key='lang-checkbox' className='checkbox-primary'>
                        <>
                          <FormGroup>
                            {languageDropdown.map((lang, i) => (
                              <div
                                key={i}
                                className='form-check form-check-inline mb-3 me-0'
                              >
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id={`lang-checkbox-${i}`}
                                  value={lang.value}
                                  defaultChecked={
                                    isJobDetailPage
                                      ? searchedValue?.required_languages ===
                                        lang?.value
                                      : requiredLanguagesParam === lang.value
                                  }
                                  {...register('required_languages', {
                                    onChange: (e) => langReqChange(e, i),
                                  })}
                                />
                                <label className='form-check-label'>
                                  {lang.label}
                                </label>
                              </div>
                            ))}
                          </FormGroup>
                        </>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button className='lang-btn m-1 ms-2 m-sm-1 ms-sm-2' ref={ref}>
                  {isJobDetailPage && searchedValue?.required_languages
                    ? searchedValue?.required_languages
                        .charAt(0)
                        .toUpperCase() +
                      searchedValue?.required_languages.slice(1)
                    : requiredLanguagesParam || langAdded
                    ? (requiredLanguagesParam || langAdded)
                        .charAt(0)
                        .toUpperCase() +
                      (requiredLanguagesParam || langAdded).slice(1)
                    : localLanguage
                    ? 'Language Required'
                    : JAPANESE.LanguageRequired}
                </Button>
              </OverlayTrigger>
            </div>
            <div
              className='align-self-center'
              onClick={() => ref.current.click()}
            >
              <svg
                fill='#263b5e'
                height='20'
                width='20'
                viewBox='0 0 20 20'
                aria-hidden='true'
                focusable='false'
                className='css-tj5bde-Svg'
              >
                <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
              </svg>
            </div>
          </div>
          <div className='search-input search-width-job-policy'>
            <Controller
              id='remote_policy'
              name='remote_policy'
              control={control}
              render={({ field }) => (
                <Select
                  options={remoteOption}
                  classNamePrefix='react-select'
                  placeholder={
                    localLanguage ? 'Remote Policy' : JAPANESE.RemotePolicy
                  }
                  {...field}
                  isClearable
                />
              )}
            />
          </div>
        </div>
        <div className='text-lg-start text-center position-relative'>
          <Button className='search-btn' type='submit'>
            {'Search'}
          </Button>
          {isMobile === false && (
            <span
              className='clear cursor-pointer position-absolute search-clear'
              onClick={() => resetHandler()}
            >
              {localLanguage ? 'Clear' : JAPANESE.Clear}
            </span>
          )}
        </div>
        {isMobile === true && (
          <div className='d-flex flex-row align-items-center p-1 gap-3 mt-3'>
            <div
              className='clear cursor-pointer search-clear'
              onClick={() => resetHandler()}
            >
              {localLanguage ? 'Clear' : JAPANESE.Clear}
            </div>
          </div>
        )}
      </Form>
    </>
  );
};

export default SearchBar;
