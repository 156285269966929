import React from "react";
import { Col, Row } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <>
     
      <div className=''>
        <Row
          className='align-items-center'

        >
          <Col md={3}></Col>
          <Col md={6}>
            <div className='banner-block '>
              <h2>個人情報保護方針</h2>
              <div className='p-3'>
                <h5>１．基本方針</h5>
                <p>
                  株式会社ExecutiveSearch.AIに従事するすべての者は、インターネットを活用した情報サービス等をご提供するうえで、個人情報の保護は、当社の重要な責務であるということを認識するとともに、個人情報保護マネジメントシステムを遵守し、個人情報を正確かつ安全に取り扱うことによりお客様の情報を守り、その信頼に応え続けて参ります。
                </p>
                <h5>２．法令等の遵守</h5>
                <p>
                  当社は、個人情報を取り扱うにあたり、「個人情報の保護に関する法律」をはじめとする個人情報の保護に関する法令、国が定めるガイドライン、その他個人情報保護に関する規範及び本個人情報保護方針を遵守致します。
                </p>
                <h5>３．個人情報の取り扱い</h5>
                <p>
                  （1）個人情報の取得・利用・提供個人情報の取得・利用及び提供については、「個人情報のお取り扱いについて」に記載する利用目的の範囲内で、情報サービス等の内容や規模を考慮した適切な取り扱いを行います。当社においては、取得または預託された個人情報を利用目的以外に利用致しません。利用目的を超えて個人情報を利用する場合は、予め本人の同意を得たうえで利用するものとします。
                </p>
                <p>
                  （2）個人情報の適正な管理・個人情報への不正アクセス、個人情報の紛失、破壊、改ざん及び漏洩等の予防ならびに是正に関しては、適正かつ合理的な水準での安全管理体制を維持します。
                  ・個人情報へのアクセスは、必要最小限の役職員に限定します。
                  ・個人情報の取り扱いを外部に委託する場合は、秘密保持契約等の締結とともに必要な監査を行い、その実効性を担保します。
                  ・当社は個人情報に関する本人の権利を尊重し、個人情報に対して開示、訂正及び削除を求められた場合は、合理的な手続を通してこれに応じます。
                </p>
                <h5>４．教育</h5>
                <p>
                  個人情報の保護に関する教育を、当社は役職員に対して継続的に行うものとし、個人情報の適切な取り扱いを実践致します。
                </p>
                <h5>５．苦情及び相談への対応</h5>
                <p>
                  個人情報の取り扱いに関する苦情及び相談を受けた場合には、その内容について迅速に事実関係等を調査し、合理的な期間内に誠意をもって対応致します。
                </p>
                <h5>６．個人情報保護マネジメントシステムの継続的な改善</h5>
                <p>2018年2月1日制定</p>

                <p>2020年4月13日改定</p>

                <p>株式会社ExecutiveSearch.AI</p>

                <p>代表取締役社長　Ken Charles</p>
                <p> ————</p>

                <p>当社の個人情報に関するご相談窓口</p>

                <p>
                  本個人情報保護方針に関するお問い合わせは、下記までお願いします。
                </p>

                <p>
                  〒150-0002 東京都渋谷区渋谷1-10-7グローリア宮益坂III801号室
                </p>

                <p>株式会社ExecutiveSearch.AI（個人情報保護管理者）</p>

                <p>
                  なお、各情報サービス等を通じてご提供いただいたお客様の個人情報に関するお問い合わせは、
                  お客様がご利用されている情報サービス等のお問い合わせ窓口にて承ります。
                </p>

                <p>————</p>
                <h6>個人情報のセキュリティについて</h6>

                <p>
                  個人情報の登録や送信の際のセキュリティについて、SSL通信による暗号化を行っています。これは、情報サービス等の運営主体である株式会社ExecutiveSearch.AIの実在性を証明する機能を有し、ウェブサーバとブラウザ間の通信を暗号化することを実現するものです。
                </p>

                <p>
                  情報サービス等におけるインターネットサイトでは、個人データの登録・更新、また、企業等への個人情報の送信等が、SSL通信により安全に行われるようになっております。
                  また、会員の個人情報を保管するデータベースサーバは、24時間管理のセキュリティ設備のあるデータセンターで厳重に管理されています。
                </p>

                <p>
                  また、このサーバに保管されているデータへのアクセスは、当社または、当社の契約する運用代行会社の一部の人間に厳しく制限されています。
                </p>

                <p>————</p>
                <h6>個人情報の利用について</h6>

                <h6 className='mt-4'>１．個人情報の利用目的</h6>

                <p>
                  当社が取得した個人情報は、利用目的を別途通知等する場合を除き、あらかじめ明示した利用目的の範囲内で使用するものとします。当社の保有する個人情報の利用目的については、次のとおりです。
                </p>
                <ul className='p-1 ms-5'>
                  <li>
                    当社が運営する情報サービス等の登録者に対する、情報提供及び適切な情報のマッチング
                  </li>
                  当社が開催する研修の運営
                  <li>応募者の承諾・同意に基づく求人企業への応募・開示 </li>
                  <li>
                    アンケートの実施、キャンペーン、モニターなどへの応募、プレゼント発送{" "}
                  </li>
                  <li>
                    その他、当社が運営する各種サービスに関する情報等の提供
                  </li>
                  <li>
                    お取引先の採用業務代行（お取引先に対する応募者への電話連絡・テスト採点・メール送信・パンフレット等の発送）
                  </li>
                  <li>人事システムの販売・提供 </li>
                  <li>お取引先の人事関連業務代行</li>
                  <li>お取引先の人事労務コンサルティング</li>
                  <li>展示会、セミナーの開催及びご案内</li>
                  <li>お問合せ、苦情及び相談への対応</li>
                  <li>株主への会議ご案内及び各種ご連絡</li>
                  <li>情報システム、施設への入退管理等の安全管理</li>
                  <li>当社の採用活動</li>
                  <li>当社の人事労務管理、福利厚生管理</li>
                  <li>当社の営業活動及び定款記載の業務</li>
                </ul>
                <h6>２．個人情報の第三者への提供</h6>
                <p>
                  当社が取得した個人情報は、次の場合を除いて第三者に提供致しません。
                </p>
                <ul className='p-1 ms-5'>
                  <li>本人の同意がある場合</li>

                  <li>法令に基づき、提供に応じなければならない場合</li>

                  <li>
                    人の生命、身体または財産保護のために必要がある場合であって、本人の同意を得ることが困難な場合
                  </li>

                  <li>
                    公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難な場合
                  </li>

                  <li>
                    国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
                  </li>

                  <li>利用目的の達成に必要な範囲で業務を外部委託する場合</li>

                  <li>合併その他の事由による事業の承継に伴って提供する場合</li>
                </ul>
                <h6>３．開示請求等の手続きについて</h6>
                <p>
                  お客様本人からご提供いただきました個人情報は、法令の定めに基づき、本人の意思により開示、訂正または削除等を行うことができます。本人が直接、開示、訂正または削除等を行えない場合は、当社は本人の確認作業を行わせていただくことがございます。本人確認完了後、ご請求いただいた開示、訂正または削除の作業を行います。
                </p>
                <p>
                  個人情報をご提供いただいたお客様本人からの、個人情報の開示、訂正または削除等のご連絡につきましては、こちらの
                  <a
                    style={{ textDecoration: "none", color: "#1d34c9" }}
                    href='https://drive.google.com/file/d/15iomX8kwKvgzoDmTOT8ivBBoN6Rgs04_/view'
                  >
                    「申請書フォーム(PDF)」
                  </a>
                  に必要事項をご記入のうえ、お客様の本人確認ができる書類（運転免許証、保険証、パスポート等）の写しとともに、当社までお送りくださいますようお願い致します。
                </p>

                <p>
                  なお、当該請求書を送付される際には、配達記録郵便など配達の記録が残る方法にてご送付ください。
                </p>

                <p>
                  ※お客様から必要とされる個人情報の提供が行われなかった場合は、お客様のお問合せに応じられない等の不都合が生じる場合がございます。
                </p>
              </div>
            </div>
          </Col>
          <Col md={3}></Col>
       
        </Row>
      
      </div>
    </>
  );
};

export default PrivacyPolicy;
