import React, { useCallback, useContext, useRef, useState } from "react";
import "../Searchpage/search.scss";
import "../../index.scss";
import {
  Button,
  Col,
  Row,
  ListGroup,
  Form,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import {
  pageSize,
  formatNumber,
  companyStageOptions,
  companyStageJapaneseOptions,
} from "../Constant/constant";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allPositions,
  companyById,
  companyList,
  companyListName,
  industriesAPI,
  removeCompanydetails,
} from "../redux/actions";
import { useLocation, useNavigate, useParams } from "react-router";
import { Heart } from "react-feather";
import ReactPaginate from "react-paginate";
import { useMemo } from "react";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import Select from "react-select";
import FavouritesContext from "../Context/FavouritesContext";
import LanguageContext from "../Context/LanguageContext";
import { JAPANESE } from "../Constant/LangauageTranslate";
import ShareModel from "../ShareModel/shareModel";

const copyURL = () => {
  var data = {
    domain: "esaijobs.com",
    originalURL: window.location.href,
  };

  fetch("https://api.short.io/links/public", {
    method: "post",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      authorization: "pk_AkRowlwdDyx9ikA4",
    },
    body: JSON.stringify(data),
  })
    .then(function (response) {
      return response.json();
    })
    .then(function (data) {
      copy(data.shortURL || window.location.href);
      toast.success("Copied link!");
    });
};

const Jobscount = ({ positions }) => {
  const [isMobile, setIsMobile] = useState(false);
  const lngCtx = useContext(LanguageContext);
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <Row>
      <Col md={5} className='ms-auto'>
        {!isMobile ? (
          <div className='d-flex'>
            <label className='label-list'>
              {lngCtx.localLanguage ? "Jobs For You" : JAPANESE.JobsForYou}:{" "}
              <span className='skin_color'>{positions?.count}</span>
            </label>
            <div className='copy-search position-relative ms-3 top-0'>
              <a
                target='_blank'
                className='detail-text hover-a-color cursor-pointer'
                onClick={() => {
                  copyURL();
                }}
              >
                {lngCtx.localLanguage
                  ? "Copy Search Parameters"
                  : JAPANESE.CopySearchParameters}
              </a>
            </div>
          </div>
        ) : (
          <div className='align-items-center d-flex flex-column'>
            <div>
              <label className='label-list'>
                {lngCtx.localLanguage ? "Jobs For You:" : JAPANESE.JobsForYou}
                <span className='skin_color'>{positions?.count}</span>
              </label>
            </div>
            <div className='copy-search position-relative mt-2 top-0'>
              <a
                target='_blank'
                className='detail-text hover-a-color cursor-pointer'
                onClick={() => {
                  copyURL();
                }}
              >
                {lngCtx.localLanguage
                  ? "Copy Search Parameters"
                  : JAPANESE.CopySearchParameters}
              </a>
            </div>
          </div>
        )}
      </Col>
      <Col md={5} className=''></Col>
    </Row>
  );
};

const JobListBlock = ({ page, resetPage, setResetPage }) => {
  const [isMobile, setIsMobile] = useState(false);
  const lngCtx = useContext(LanguageContext);
  const favCtx = useContext(FavouritesContext);

  const dispatch = useDispatch();
  // const companies = useSelector((state) => state.search.companyList);
  const companiesName = useSelector((state) => state.search.companyListName);
  const positions = useSelector((state) => state.search.positions);

  const industriesArr = useSelector((state) => state.search.industries);
  const lang = useSelector((state) => state.search.selectedLang);
  const searchedVal = useSelector((state) => state.search.searchedValue);
  const companydetails = useSelector((state) => state.search.companydetail);
  const focusValues = useSelector((state) => state.search.focusValues);

  const favoritesArr = localStorage.getItem("favorites");

  const location = useLocation();
  const navigate = useNavigate();

  const paramsData = useParams();

  const [selectedPage, setSelectedPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [posWithFavFlag, setPosWithFavFlag] = useState(null);
  const [companyClick, setCompanyClick] = useState(false);
  const [industryData, setIndustryData] = useState([]);

  const [focusValArr, setFocusValArr] = useState([]);

  const [selectedInduVal, setSelectedInduVal] = useState(null);

  const myParam = useLocation().search;
  const filterdata = new URLSearchParams(myParam);
  const selectInputRefFocus = useRef();
  const selectInputRefCompany = useRef();
  const selectInputRefCompanyStage = useRef();

  const [show, setShow] = useState(false);
  const [modelDetails, setModelDetails] = useState({ logo: "", name: "" });

  // Recent search parameters
  const queryParams = new URLSearchParams(location.search);
  const focusParam = queryParams.get("focus");
  const industryParam = queryParams.get("industry");
  const companyParam = queryParams.get("company_id");
  const companyStageParam = queryParams.get("company_stage");

  const handleClose = () => setShow(false);
  const modelHandler = (e, posId, selLogo, selName, selIndustry) => {
    e.preventDefault();

    setShow(true);
    setModelDetails({
      posId: posId,
      logo: selLogo,
      name: selName,
      industry: selIndustry,
    });
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "auto", // You can use 'auto' for instant scrolling
    });
  }, [location]);
  const copyJobURL = (id) => {
    var data = {
      domain: "671z.short.gy",
      originalURL: `${window.location.origin}/jobdetail/${id}`,
    };
    fetch("https://api.short.io/links/public", {
      method: "post",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        authorization: "pk_8eMW2pLPldPJwxsy",
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        copy(data.shortURL);
        toast.success("Copied link!");
      });
  };
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setCompanyClick(false);
    if (location.pathname === "/jobsearch") {
      dispatch(removeCompanydetails(null));
    }
  }, []);

  let obj = {
    lang: lang || "en",
    name: filterdata.get("name")
      ? filterdata.get("name")
      : !searchedVal?.search && !location?.state?.searchvalue
      ? ""
      : location?.state?.searchvalue
      ? location?.state?.searchvalue
      : searchedVal?.search,
    page: selectedPage + 1,
    role: filterdata.get("role")
      ? filterdata.get("role")
      : searchedVal?.role
      ? searchedVal?.role
      : "",
    remote_policy: filterdata.get("remote_policy")
      ? filterdata.get("remote_policy")
      : searchedVal?.remote_policy
      ? searchedVal?.remote_policy
      : "",
    required_languages: filterdata.get("required_languages")
      ? filterdata.get("required_languages")
      : searchedVal?.required_languages
      ? searchedVal?.required_languages
      : "",
    industry: filterdata.get("industry")
      ? filterdata.get("industry")
      : selectedInduVal == null
      ? ""
      : selectedInduVal,
    focus: filterdata.get("focus") ? filterdata.get("focus") : "",
    company_id: filterdata.get("company_id")
      ? filterdata.get("company_id")
      : "",
    company_stage: filterdata.get("company_stage")
      ? filterdata.get("company_stage")
      : "",
  };

  const favFlagHandler = useCallback(() => {
    if (positions) {
      let posWithFlag = positions?.data?.map((pos) => {
        if (JSON.parse(favoritesArr)?.includes(pos?.id)) {
          return { ...pos, isFav: true };
        } else {
          return { ...pos, isFav: false };
        }
      });
      setPosWithFavFlag(posWithFlag);
    }
  }, [favoritesArr, positions]);

  useEffect(() => {
    favFlagHandler();
    setPageCount(Math.ceil(positions?.count / pageSize));
  }, [favFlagHandler, positions, resetPage]);

  useMemo(() => {
    if (searchedVal) {
      dispatch(allPositions(obj));
      dispatch(companyListName(obj));
    }
  }, [searchedVal]);

  useMemo(() => {
    if (location.pathname === "/favorites") {
      dispatch(allPositions(obj));
      dispatch(industriesAPI(obj));
      dispatch(companyList(obj));
    }
  }, [location.pathname]);
  useMemo(() => {
    if (lang) {
      dispatch(allPositions(obj));
      dispatch(companyListName(obj));
      dispatch(industriesAPI(obj));
      dispatch(companyList(obj));
    }
  }, [lang]);

  useEffect(() => {
    let focusValupdate = [];
    if (focusValues.length === 0) {
    } else {
      focusValues?.forEach((x) => {
        focusValupdate.push({ label: x, value: x });
      });
    }
    setFocusValArr(focusValupdate);
  }, [focusValues]);

  useEffect(() => {
    const indupdatedArr = [];
    industriesArr?.forEach((x) => {
      if (filterdata.get("industry")) {
        if (x === filterdata.get("industry")) {
          indupdatedArr.push({ name: x, isChecked: true });
        } else {
          indupdatedArr.push({ name: x, isChecked: false });
        }
      } else {
        indupdatedArr.push({ name: x, isChecked: false });
      }
    });
    setIndustryData(indupdatedArr);
  }, [industriesArr]);

  useEffect(() => {
    if (selectedInduVal !== null) {
      dispatch(allPositions(obj));
    }
  }, [selectedInduVal]);

  const learnmoreHandler = (id) => {
    if (window.innerWidth <= 420) {
      var windowSize =
        "width=" +
        window.innerWidth +
        ",height=" +
        window.innerHeight +
        ",scrollbars=no";
      window.open(`/jobdetail/${id}`, "popup", windowSize);
      window.scrollTo(0, 0);
    } else {
      navigate(`/jobdetail/${id}`);
      window.scrollTo(0, 0);
    }
  };

  const favHandler = (e, pos) => {
    e.preventDefault();
    const data = [...posWithFavFlag];
    const index = data.findIndex((o) => o.id === pos.id);
    data[index] = { ...pos, isFav: !pos?.isFav };
    setPosWithFavFlag(data);
    const stored = localStorage.getItem("favorites") ?? "[]";
    let filterArr = [...new Set([...favCtx.favArr, ...JSON.parse(stored)])];
    let isEle = filterArr.length > 0 && filterArr.find((x) => x === pos.id);
    if (isEle) {
      let Ar1 = filterArr.filter((x) => x !== pos.id);
      favCtx.setFavArr(Ar1);
      localStorage.setItem("favorites", JSON.stringify(Ar1));
    } else {
      filterArr.push(pos.id);
      favCtx.setFavArr(filterArr);
      localStorage.setItem("favorites", JSON.stringify(filterArr));
    }
  };

  useEffect(() => {
    if (posWithFavFlag) {
      const data = [...posWithFavFlag];
      const index = data.findIndex((o) => o.id === paramsData?.id);
      data[index] = { ...data[index], isFav: !data[index]?.isFav };
      setPosWithFavFlag(data);
    }
  }, [favoritesArr]);

  const logoclickHandler = (id) => {
    setCompanyClick(true);
    const obj = {
      id: id,
      lang: lang,
    };
    dispatch(companyById(obj));
  };

  const handlePageClick = (e) => {
    setSelectedPage(e.selected);
    dispatch(allPositions({ ...obj, page: e.selected + 1 }));
  };

  const industryChange = (e, i) => {
    const updateinduArr = [...industryData];
    if (selectedInduVal === updateinduArr[i].name) {
      setSelectedInduVal("");
      navigate({
        pathname: "/jobsearch",
        search: location.search.replace(`&industry=${selectedInduVal}`, ""),
      });
    } else {
      setSelectedInduVal(updateinduArr[i].name);
      navigate({
        pathname: "/jobsearch",
        search: location.search.includes("&industry")
          ? location.search.replace(
              `&industry=${filterdata.get("industry")}`,
              `&industry=${updateinduArr[i].name}`
            )
          : location.search + `&industry=${updateinduArr[i].name}`,
        // search:`&industry=${updateinduArr[i].name}`,
      });
    }
    let checkedUpdated = [];
    updateinduArr.forEach((x, index) => {
      if (i === index) {
        checkedUpdated.push({ ...x, isChecked: e.target.checked });
      } else {
        checkedUpdated.push({ ...x, isChecked: false });
      }
    });
    setIndustryData(checkedUpdated);
  };

  const clearindustry = () => {
    const updateinduArr = [];
    industryData?.forEach((x) => {
      updateinduArr.push({ ...x, isChecked: false });
    });
    navigate({
      pathname: "/jobsearch",
      search: location.search.includes("&industry")
        ? location.search.replace(`&industry=${filterdata.get("industry")}`, "")
        : location.search,
    });
    setIndustryData(updateinduArr);
    setSelectedInduVal("");
  };

  const focusChange = (e, i) => {
    let obj1 = {};
    if (e?.value) {
      navigate({
        pathname: "/jobsearch",
        search: location.search.includes("&focus")
          ? location.search.replace(
              `&focus=${filterdata.get("focus")}`,
              `&focus=${e.value}`
            )
          : location.search + `&focus=${e.value}`,
      });
      obj1 = { ...obj, page: 1, focus: e.value };

      setSelectedPage(0);
    } else {
      navigate({
        pathname: "/jobsearch",
        search: location.search.includes("&focus")
          ? location.search
          : // ? location.search.replace(`&focus=${filterdata.get('focus')}`, '')
            location.search,
      });
      obj1 = { ...obj, page: 1, focus: focusParam ? focusParam : "" };
      setSelectedPage(0);
    }

    if (obj1.focus !== null) {
      dispatch(allPositions(obj1));
    }
  };

  // const openSalCompanyOpt = companies?.data?.map((comp) => ({
  //   label: comp.name,
  //   value: comp.id,
  // }));
  const openSalCompanyOptName = companiesName?.data?.map((comp) => ({
    label: comp.name,
    value: comp.id,
  }));

  const companyStageChangeHandler = (e) => {
    let obj1 = {};
    if (e?.value) {
      navigate({
        pathname: "/jobsearch",
        search: location.search.includes("&company_stage")
          ? location.search.replace(
              `&company_stage=${filterdata.get("company_stage")}`,
              `&company_stage=${e.value}`
            )
          : location.search + `&company_stage=${e.value}`,
      });
      obj1 = { ...obj, page: 1, company_stage: e.value };
      setSelectedPage(0);
    } else {
      navigate({
        pathname: "/jobsearch",
        search: location.search.includes("&company_stage")
          ? location.search
          : location.search,
      });
      obj1 = {
        ...obj,
        page: 1,
        company_stage: companyStageParam ? companyStageParam : "",
      };
      setSelectedPage(0);
    }

    if (obj1.company_stage !== null) {
      dispatch(allPositions(obj1));
    }
  };
  const compChangeHandler = (e) => {
    let obj1 = {};

    if (e?.value) {
      navigate({
        pathname: "/jobsearch",
        search: location.search.includes("&company_id")
          ? location.search.replace(
              `&company_id=${filterdata.get("company_id")}`,
              `&company_id=${e.value}`
            )
          : location.search + `&company_id=${e.value}`,
      });
      obj1 = { ...obj, page: 1, company_id: e.value };
      setSelectedPage(0);
    } else {
      navigate({
        pathname: "/jobsearch",
        search: location.search.includes("&company_id")
          ? location.search
          : location.search,
      });
      obj1 = { ...obj, page: 1, company_id: companyParam ? companyParam : "" };
      setSelectedPage(0);
    }

    if (obj1.company_id !== null) {
      dispatch(allPositions(obj1));
    }
  };
  // useEffect(() => {
  //   if (resetPage === true) {
  //     setSelectedPage(0);
  //     dispatch(allPositions({ ...obj, page: 1 }));
  //     setResetPage(false);
  //   }
  // }, [resetPage]);
  useEffect(() => {
    if (resetPage === true) {
      setSelectedPage(0);
      onClear();
      setResetPage(false);
    }
  }, [resetPage]);
  const onClear = () => {
    selectInputRefFocus.current.clearValue();
    selectInputRefCompany.current.clearValue();
    selectInputRefCompanyStage.current.clearValue();
    clearindustry();
    navigate({
      pathname: "/jobsearch",
    });
  };
  useEffect(() => {
    if (selectedInduVal !== "") {
      selectInputRefFocus?.current?.clearValue();
      selectInputRefCompany?.current?.clearValue();
      selectInputRefCompanyStage?.current?.clearValue();
    }
  }, [selectedInduVal]);

  const jobListSideMenu = (
    <Col sm={12} md={2}>
      <div className='select-catagory-block'>
        <div className='category-block d-flex justify-content-between mb-2'>
          <span></span>
          <span className='cursor-pointer clearall' onClick={() => onClear()}>
            {lngCtx.localLanguage ? "Clear All" : JAPANESE.ClearAll}
          </span>
        </div>
        <div className='select-catagory'>
          <div className='category-block d-flex justify-content-between'>
            <h3>{lngCtx.localLanguage ? "Specialty" : JAPANESE.Speciality}</h3>
          </div>
          <div className='checkbox-list'>
            <Select
              ref={selectInputRefFocus}
              options={focusValArr}
              value={focusParam && { label: focusParam, value: focusParam }}
              placeholder={lngCtx.localLanguage ? "Choose" : JAPANESE.Choose}
              classNamePrefix='react-select'
              onChange={(e) => focusChange(e)}
            />
          </div>
        </div>
        <div className='select-catagory'>
          <div className='category-block d-flex justify-content-between'>
            <h3>
              {lngCtx.localLanguage ? "Company Stage" : JAPANESE.CompanyStage}
            </h3>
          </div>
          <div className='flex-grow-1 search-input with-icon'>
            <div className='relative company-search'>
              <Select
                ref={selectInputRefCompanyStage}
                options={
                  lngCtx.localLanguage
                    ? companyStageOptions
                    : companyStageJapaneseOptions
                }
                value={
                  companyStageParam &&
                  (lngCtx.localLanguage
                    ? companyStageOptions
                    : companyStageJapaneseOptions
                  )?.find((com) => com.value === companyStageParam)
                }
                placeholder={lngCtx.localLanguage ? "Choose" : JAPANESE.Choose}
                classNamePrefix='react-select'
                onChange={(e) => companyStageChangeHandler(e)}
              />
            </div>
          </div>
        </div>
        <div className='select-catagory'>
          <div className='category-block d-flex justify-content-between'>
            <h3>{lngCtx.localLanguage ? "Company" : JAPANESE.Company}</h3>
          </div>
          <div className='flex-grow-1 search-input with-icon'>
            <div className='relative company-search'>
              <Select
                ref={selectInputRefCompany}
                options={openSalCompanyOptName}
                value={
                  companyParam &&
                  openSalCompanyOptName?.find(
                    (com) => com.value === companyParam
                  )
                }
                placeholder={lngCtx.localLanguage ? "Choose" : JAPANESE.Choose}
                classNamePrefix='react-select'
                onChange={(e) => compChangeHandler(e)}
              />
            </div>
          </div>
        </div>
        <div className='select-catagory'>
          <div className='category-block d-flex justify-content-between'>
            <h3>{lngCtx.localLanguage ? "Industry" : JAPANESE.Industry}</h3>
          </div>
          <div className='checkbox-list'>
            <ListGroup>
              <ListGroup.Item>
                <Form>
                  {industryData?.map((type, i) => (
                    <div className='checkbox-primary' key={i}>
                      <Form.Check
                        type='checkbox'
                        id={`default-${type.name}`}
                        defaultChecked={industryParam ? industryParam : ""}
                        name={`industry-${type.name}`}
                        label={type.name}
                        checked={type.isChecked}
                        onChange={(e) => industryChange(e, i)}
                      />
                    </div>
                  ))}
                </Form>
                <span
                  className='clear cursor-pointer'
                  onClick={() => clearindustry()}
                >
                  {lngCtx.localLanguage ? "Clear" : JAPANESE.Clear}
                </span>
              </ListGroup.Item>
            </ListGroup>
          </div>
        </div>
      </div>
    </Col>
  );

  return (
    <>
      <div className={page === "jobList" ? "joblist mt-md-40 mt-3" : "joblist"}>
        {companyClick ? (
          companydetails ? (
            <Row className='g-4 mb-4'>
              {/* Individual comapny positions on the click of company logo */}
              {companydetails?.positions?.map((pos, i) => (
                <Col md={page === "jobdetail" ? 12 : 6} key={i}>
                  <div className='job-list-block'>
                    <div className='job-header'>
                      <div className='job-title'>
                        {companydetails?.logo ? (
                          <div className='job-logo'>
                            <img
                              src={companydetails?.logo}
                              alt='logo'
                              onClick={() =>
                                logoclickHandler(companydetails.id)
                              }
                            />
                          </div>
                        ) : null}
                        <div className='title-desc'>
                          <h3>{pos?.name}</h3>
                          <p>{pos?.company} </p>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div
                      className='job-content-new p-1 m-1'
                      onClick={() => learnmoreHandler(pos.id)}
                    >
                      <div className='row icon-box d-flex flex-nowrap  align-items-start'>
                        <div className='col-4 icon-desc '>
                          <h4>
                            {lngCtx.localLanguage
                              ? "Industry Average Salary For Role"
                              : JAPANESE.IndustryAverageforRole}

                            {!!pos?.industry_avg_url && (
                              <a
                                href={pos?.industry_avg_url}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <button className='align-items-center cursor-pointer skin_color salaryArkstrik-btn'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip>
                                        {lngCtx.localLanguage
                                          ? "Salary info is crowdsourced on OpenSalary. Click to view more."
                                          : JAPANESE.SalaryInfo}
                                      </Tooltip>
                                    }
                                  >
                                    <span className='d-inline-flex align-items-center cursor-pointer skin_color'>
                                      *
                                    </span>
                                  </OverlayTrigger>
                                </button>
                              </a>
                            )}
                          </h4>
                        </div>
                        <div className='col-4 icon-desc'>
                          <h4>
                            {lngCtx.localLanguage
                              ? "Company Average Salary For Role"
                              : JAPANESE.CompanyAverageforRole}
                            {!!pos?.company_avg_url && (
                              <a
                                href={pos?.company_avg_url}
                                target='_blank'
                                rel='noreferrer'
                              >
                                <button className='align-items-center cursor-pointer skin_color salaryArkstrik-btn'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip>
                                        {lngCtx.localLanguage
                                          ? "Salary info is crowdsourced on OpenSalary. Click to view more."
                                          : JAPANESE.SalaryInfo}
                                      </Tooltip>
                                    }
                                  >
                                    <span className='d-inline-flex align-items-center cursor-pointer skin_color'>
                                      *
                                    </span>
                                  </OverlayTrigger>
                                </button>
                              </a>
                            )}
                          </h4>
                        </div>
                        <div className='col-4 icon-desc'>
                          <h4>
                            {lngCtx.localLanguage
                              ? "Location"
                              : JAPANESE.Location}
                          </h4>
                        </div>
                      </div>
                      <div className='row icon-box d-flex flex-nowrap  align-items-start'>
                        <div className='col-4 icon-desc'>
                          <h3>
                            {!pos.industry_avg
                              ? "-"
                              : `${formatNumber(
                                  parseInt(pos?.industry_avg)
                                )}  ${
                                  lngCtx.localLanguage ? "yen" : JAPANESE.yen
                                }`}{" "}
                          </h3>
                        </div>
                        <div className='col-4 icon-desc'>
                          <h3>
                            {!pos.company_avg
                              ? "-"
                              : `${formatNumber(parseInt(pos?.company_avg))} ${
                                  lngCtx.localLanguage ? "yen" : JAPANESE.yen
                                }`}{" "}
                          </h3>
                        </div>
                        <div className='col-4 icon-desc'>
                          <h3>{pos.location}</h3>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div
                      className={`job-detail-footer ${
                        isMobile && "flex-column "
                      }`}
                    >
                      <div
                        className={`details-label ${
                          isMobile && "justify-content-center "
                        }`}
                      >
                        {pos?.remote_policy === "Full Remote" ? (
                          <span>Remote</span>
                        ) : pos?.remote_policy === "Hybrid Remote" ? (
                          <span>Hybrid</span>
                        ) : (
                          <span>On Site</span>
                        )}

                        {pos?.role && <span>{pos?.role}</span>}
                        {pos?.focus && <span>{pos?.focus}</span>}
                      </div>
                      <div>
                        <Button
                          veriant='primary'
                          className='btn-md'
                          onClick={() => learnmoreHandler(pos?.id)}
                        >
                          {lngCtx.localLanguage
                            ? "Learn More"
                            : JAPANESE.LearnMore}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          ) : (
            <div className='d-flex justify-content-center my-1 gap-1 spinner-block'>
              <Spinner animation='grow' variant='secondary' />
              <span className=''>Loading...</span>
            </div>
          )
        ) : companiesName && positions ? (
          <>
            {page === "jobList" && isMobile && jobListSideMenu}
            {page === "jobList" ? <Jobscount positions={positions} /> : null}

            <Row className={page === "jobList" ? "mt-3 mb-4" : "mb-4"}>
              {page === "jobList" && !isMobile && jobListSideMenu}
              <Col md={page === "jobList" ? 10 : 12}>
                {page === "favorites" ? (
                  <Row className='g-4 mb-4 me-auto'>
                    {companiesName?.data?.map((comp, index) =>
                      posWithFavFlag?.map((pos, i) =>
                        favCtx.favArr?.map((fav) => {
                          if (fav === pos.id) {
                            if (comp.id === pos.company_id) {
                              return (
                                <Col
                                  md={5}
                                  lg={page === "jobdetail" && 12}
                                  key={`pos-${i}`}
                                >
                                  <div
                                    className={
                                      paramsData?.id === pos.id
                                        ? "job-list-block active"
                                        : "job-list-block"
                                    }
                                  >
                                    <div className='job-header'>
                                      <div className='job-title'>
                                        {pos?.company_logo ? (
                                          <div className='job-logo'>
                                            <img
                                              alt='logo'
                                              src={pos?.company_logo}
                                              className='cursor-pointer'
                                              onClick={() =>
                                                logoclickHandler(comp.id)
                                              }
                                            />
                                          </div>
                                        ) : null}
                                        <div className='title-desc'>
                                          <h3>{pos?.name} </h3>
                                          <p>{pos?.company}</p>
                                        </div>
                                      </div>
                                      <div className='wishlist d-flex'>
                                        <OverlayTrigger
                                          placement='top'
                                          overlay={
                                            <Tooltip>
                                              {" "}
                                              {lngCtx.localLanguage
                                                ? "Copy Job Link"
                                                : JAPANESE.CopyJobLink}
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            className='me-2 cursor-pointer'
                                            onClick={() => copyJobURL(pos.id)}
                                          >
                                            <svg
                                              width='24'
                                              height='24'
                                              viewBox='0 0 20 18'
                                              fill='none'
                                              xmlns='http://www.w3.org/2000/svg'
                                            >
                                              <path
                                                fillRule='evenodd'
                                                clipRule='evenodd'
                                                d='M7.43267 3.59896C7.48573 3.5459 7.52653 3.48463 7.55534 3.41528C7.58401 3.34605 7.59841 3.27379 7.59841 3.19875C7.59841 3.04249 7.54321 2.90909 7.43267 2.79855C7.32214 2.68802 7.18874 2.63281 7.03247 2.63281H2.12699C1.6582 2.63281 1.25826 2.79855 0.926778 3.1299C0.595428 3.46138 0.429688 3.86133 0.429688 4.33012V16.0272C0.429688 17.1587 0.995499 17.7245 2.12699 17.7245H13.8241C14.9556 17.7245 15.5214 17.1587 15.5214 16.0272V11.1217C15.5214 10.9655 15.4662 10.8321 15.3557 10.7215C15.2451 10.611 15.1117 10.5558 14.9555 10.5558C14.8804 10.5558 14.8082 10.5702 14.7389 10.5989C14.6696 10.6277 14.6083 10.6685 14.5553 10.7215C14.5022 10.7746 14.4614 10.8359 14.4326 10.9052C14.4039 10.9744 14.3895 11.0467 14.3895 11.1217V16.0272C14.3895 16.4042 14.201 16.5926 13.8241 16.5926H2.12699C1.75004 16.5926 1.56157 16.4042 1.56157 16.0272V4.33012C1.56157 3.95317 1.75005 3.7647 2.12699 3.7647H7.03247C7.10751 3.7647 7.17977 3.75029 7.24899 3.72162C7.31835 3.69294 7.37962 3.65201 7.43267 3.59896Z'
                                                fill='#6754E2'
                                              />
                                              <path
                                                fillRule='evenodd'
                                                clipRule='evenodd'
                                                d='M14.1059 7.8755C14.0934 7.87298 14.081 7.87046 14.0685 7.86794C12.3108 7.51578 10.681 7.88372 9.17881 8.97206C7.64205 10.0855 6.51962 11.7476 5.81162 13.9589C5.73899 14.1854 5.67071 14.4179 5.60689 14.6561C5.58624 14.7643 5.53637 14.8605 5.45717 14.9444C5.44743 14.9549 5.43717 14.9651 5.42664 14.9748C5.32033 15.074 5.19468 15.1259 5.04957 15.1304C5.0322 15.131 5.01483 15.1309 4.99746 15.1301C4.90089 15.1256 4.81287 15.0999 4.73366 15.0528C4.66564 15.0142 4.60749 14.9625 4.55946 14.8975C4.51117 14.8353 4.47749 14.7669 4.45815 14.6927C4.44065 14.6299 4.43421 14.5657 4.43907 14.4999C4.44578 11.0765 5.39232 8.16436 7.27867 5.76364C9.13921 3.39597 11.4149 2.16257 14.1057 2.0641V0.869139C14.1057 0.705062 14.1632 0.564992 14.2784 0.448929C14.3935 0.332866 14.5324 0.274902 14.6952 0.274902C14.759 0.274902 14.8211 0.285116 14.8816 0.30541C14.9422 0.325704 14.9978 0.355149 15.0489 0.393751L19.7644 3.95917C19.8946 4.05759 19.9712 4.18811 19.9942 4.35045C20.0173 4.51294 19.9799 4.65977 19.8823 4.7911C19.8739 4.80237 19.8652 4.81325 19.8561 4.82386C19.8469 4.83447 19.8374 4.84482 19.8275 4.85477L15.112 9.60866C14.9969 9.72472 14.8579 9.78269 14.6952 9.78269C14.5324 9.78269 14.3935 9.72472 14.2784 9.60866C14.2231 9.55295 14.1806 9.48862 14.1506 9.4158C14.1207 9.34311 14.1057 9.26724 14.1057 9.18845L14.1059 7.8755Z'
                                                fill='#6754E2'
                                              />
                                            </svg>
                                          </span>
                                        </OverlayTrigger>

                                        <Heart
                                          stroke={"#6754E2"}
                                          fill={
                                            pos.isFav ? "#6754E2" : "#ffffff"
                                          }
                                          onClick={(e) => favHandler(e, pos)}
                                          className='cursor-pointer'
                                        />
                                      </div>
                                    </div>
                                    <hr />

                                    <div
                                      className='job-content-new p-1 m-1'
                                      onClick={() => learnmoreHandler(pos.id)}
                                    >
                                      <div className='row icon-box d-flex flex-nowrap  align-items-start'>
                                        <div className='col-4 icon-desc '>
                                          <h4>
                                            {lngCtx.localLanguage
                                              ? "Industry Average Salary For Role"
                                              : JAPANESE.IndustryAverageforRole}
                                            {!!pos?.industry_avg_url && (
                                              <a
                                                href={pos?.industry_avg_url}
                                                target='_blank'
                                                rel='noreferrer'
                                              >
                                                <button className='align-items-center cursor-pointer skin_color salaryArkstrik-btn'>
                                                  <OverlayTrigger
                                                    placement='top'
                                                    overlay={
                                                      <Tooltip>
                                                        {lngCtx.localLanguage
                                                          ? "Salary info is crowdsourced on OpenSalary. Click to view more."
                                                          : JAPANESE.SalaryInfo}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span className='d-inline-flex align-items-center cursor-pointer skin_color'>
                                                      *
                                                    </span>
                                                  </OverlayTrigger>
                                                </button>
                                              </a>
                                            )}
                                          </h4>
                                        </div>
                                        <div className='col-4 icon-desc'>
                                          <h4>
                                            {lngCtx.localLanguage
                                              ? "Company Average Salary For Role"
                                              : JAPANESE.CompanyAverageforRole}
                                            {!!pos?.company_avg_url && (
                                              <a
                                                href={pos?.company_avg_url}
                                                target='_blank'
                                                rel='noreferrer'
                                              >
                                                <button className='align-items-center cursor-pointer skin_color salaryArkstrik-btn'>
                                                  <OverlayTrigger
                                                    placement='top'
                                                    overlay={
                                                      <Tooltip>
                                                        {lngCtx.localLanguage
                                                          ? "Salary info is crowdsourced on OpenSalary. Click to view more."
                                                          : JAPANESE.SalaryInfo}
                                                      </Tooltip>
                                                    }
                                                  >
                                                    <span className='d-inline-flex align-items-center cursor-pointer skin_color'>
                                                      *
                                                    </span>
                                                  </OverlayTrigger>
                                                </button>
                                              </a>
                                            )}
                                          </h4>
                                        </div>
                                        <div className='col-4 icon-desc'>
                                          <h4>
                                            {lngCtx.localLanguage
                                              ? "Location"
                                              : JAPANESE.Location}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className='row icon-box d-flex flex-nowrap  align-items-start'>
                                        <div className='col-4 icon-desc'>
                                          <h3>
                                            {!pos.industry_avg
                                              ? "-"
                                              : `${formatNumber(
                                                  parseInt(pos?.industry_avg)
                                                )} ${
                                                  lngCtx.localLanguage
                                                    ? "yen"
                                                    : JAPANESE.yen
                                                }`}{" "}
                                          </h3>
                                        </div>
                                        <div className='col-4 icon-desc'>
                                          <h3>
                                            {!pos.company_avg
                                              ? "-"
                                              : `${formatNumber(
                                                  parseInt(pos?.company_avg)
                                                )} ${
                                                  lngCtx.localLanguage
                                                    ? "yen"
                                                    : JAPANESE.yen
                                                }`}{" "}
                                          </h3>
                                        </div>
                                        <div className='col-4 icon-desc'>
                                          <h3>{pos.location}</h3>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    <div
                                      className={`job-detail-footer ${
                                        isMobile && "flex-column "
                                      }`}
                                    >
                                      <div
                                        className={`details-label ${
                                          isMobile && "justify-content-center "
                                        }`}
                                      >
                                        {pos?.remote_policy ===
                                        "Full Remote" ? (
                                          <span>Remote </span>
                                        ) : pos?.remote_policy ===
                                          "Hybrid Remote" ? (
                                          <span>Hybrid</span>
                                        ) : (
                                          <span>On Site</span>
                                        )}
                                        {pos?.required_languages && (
                                          <span>
                                            {pos?.required_languages} Required
                                          </span>
                                        )}

                                        {pos?.role && <span>{pos?.role}</span>}
                                        {pos?.focus && (
                                          <span>{pos?.focus}</span>
                                        )}
                                      </div>
                                      <div>
                                        <Button
                                          veriant='primary'
                                          className='btn-md'
                                          onClick={() =>
                                            learnmoreHandler(pos.id)
                                          }
                                        >
                                          {lngCtx.localLanguage
                                            ? "Learn More"
                                            : JAPANESE.LearnMore}
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              );
                            }
                          }
                        })
                      )
                    )}
                    {page === "jobdetail"
                      ? null
                      : favCtx.favArr.length >= pageSize && (
                          <div className='pagination-block'>
                            <ReactPaginate
                              breakLabel='...'
                              nextLabel={
                                lngCtx.localLanguage ? "next" : JAPANESE.Next
                              }
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={pageCount}
                              previousLabel={
                                lngCtx.localLanguage
                                  ? "previous"
                                  : JAPANESE.Previous
                              }
                              renderOnZeroPageCount={null}
                              breakClassName={"break-me"}
                              marginPagesDisplayed={2}
                              subContainerClassName='pages pagination'
                              breakLinkClassName='page-link'
                              containerClassName='pagination d-flex justify-content-center'
                              pageClassName='page-item'
                              pageLinkClassName='page-link'
                              previousClassName='page-item'
                              previousLinkClassName='page-link'
                              nextClassName='page-item'
                              nextLinkClassName='page-link'
                              activeClassName='active'
                              forcePage={selectedPage}
                            />
                          </div>
                        )}
                  </Row>
                ) : (
                  <Row className='g-4 mb-4'>
                    {/* job list block */}

                    {companiesName?.data?.map((comp, index) =>
                      posWithFavFlag?.map((pos, i) => {
                        if (comp.id === pos.company_id) {
                          return (
                            <Col md={6} lg={page === "jobdetail" && 12} key={i}>
                              <div
                                className={
                                  paramsData?.id === pos.id
                                    ? "job-list-block active"
                                    : "job-list-block"
                                }
                              >
                                <div className='job-header'>
                                  <div
                                    className='job-title'
                                    onClick={() => learnmoreHandler(pos.id)}
                                  >
                                    {pos?.company_logo ? (
                                      <div
                                        className={
                                          isMobile
                                            ? "job-logo-mobile"
                                            : "job-logo"
                                        }
                                      >
                                        <img
                                          src={pos?.company_logo}
                                          alt='logo'
                                          className='cursor-pointer'
                                          onClick={() =>
                                            logoclickHandler(comp.id)
                                          }
                                        />
                                      </div>
                                    ) : null}
                                    <div className='title-desc'>
                                      <h3>{pos?.name} </h3>
                                      <p>{pos?.company}</p>
                                    </div>
                                  </div>
                                  <div className='wishlist d-flex'>
                                    <OverlayTrigger
                                      placement='top'
                                      overlay={
                                        <Tooltip>
                                          {" "}
                                          {lngCtx.localLanguage
                                            ? "Share job"
                                            : JAPANESE.CopyJobLink}
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        className='me-2 cursor-pointer'
                                        onClick={(e) =>
                                          modelHandler(
                                            e,
                                            pos?.id,
                                            pos?.company_logo,
                                            pos?.name,
                                            comp?.industry
                                          )
                                        }
                                      >
                                        <svg
                                          width='24'
                                          height='24'
                                          viewBox='0 0 20 18'
                                          fill='none'
                                          xmlns='http://www.w3.org/2000/svg'
                                        >
                                          <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M7.43267 3.59896C7.48573 3.5459 7.52653 3.48463 7.55534 3.41528C7.58401 3.34605 7.59841 3.27379 7.59841 3.19875C7.59841 3.04249 7.54321 2.90909 7.43267 2.79855C7.32214 2.68802 7.18874 2.63281 7.03247 2.63281H2.12699C1.6582 2.63281 1.25826 2.79855 0.926778 3.1299C0.595428 3.46138 0.429688 3.86133 0.429688 4.33012V16.0272C0.429688 17.1587 0.995499 17.7245 2.12699 17.7245H13.8241C14.9556 17.7245 15.5214 17.1587 15.5214 16.0272V11.1217C15.5214 10.9655 15.4662 10.8321 15.3557 10.7215C15.2451 10.611 15.1117 10.5558 14.9555 10.5558C14.8804 10.5558 14.8082 10.5702 14.7389 10.5989C14.6696 10.6277 14.6083 10.6685 14.5553 10.7215C14.5022 10.7746 14.4614 10.8359 14.4326 10.9052C14.4039 10.9744 14.3895 11.0467 14.3895 11.1217V16.0272C14.3895 16.4042 14.201 16.5926 13.8241 16.5926H2.12699C1.75004 16.5926 1.56157 16.4042 1.56157 16.0272V4.33012C1.56157 3.95317 1.75005 3.7647 2.12699 3.7647H7.03247C7.10751 3.7647 7.17977 3.75029 7.24899 3.72162C7.31835 3.69294 7.37962 3.65201 7.43267 3.59896Z'
                                            fill='#6754E2'
                                          />
                                          <path
                                            fillRule='evenodd'
                                            clipRule='evenodd'
                                            d='M14.1059 7.8755C14.0934 7.87298 14.081 7.87046 14.0685 7.86794C12.3108 7.51578 10.681 7.88372 9.17881 8.97206C7.64205 10.0855 6.51962 11.7476 5.81162 13.9589C5.73899 14.1854 5.67071 14.4179 5.60689 14.6561C5.58624 14.7643 5.53637 14.8605 5.45717 14.9444C5.44743 14.9549 5.43717 14.9651 5.42664 14.9748C5.32033 15.074 5.19468 15.1259 5.04957 15.1304C5.0322 15.131 5.01483 15.1309 4.99746 15.1301C4.90089 15.1256 4.81287 15.0999 4.73366 15.0528C4.66564 15.0142 4.60749 14.9625 4.55946 14.8975C4.51117 14.8353 4.47749 14.7669 4.45815 14.6927C4.44065 14.6299 4.43421 14.5657 4.43907 14.4999C4.44578 11.0765 5.39232 8.16436 7.27867 5.76364C9.13921 3.39597 11.4149 2.16257 14.1057 2.0641V0.869139C14.1057 0.705062 14.1632 0.564992 14.2784 0.448929C14.3935 0.332866 14.5324 0.274902 14.6952 0.274902C14.759 0.274902 14.8211 0.285116 14.8816 0.30541C14.9422 0.325704 14.9978 0.355149 15.0489 0.393751L19.7644 3.95917C19.8946 4.05759 19.9712 4.18811 19.9942 4.35045C20.0173 4.51294 19.9799 4.65977 19.8823 4.7911C19.8739 4.80237 19.8652 4.81325 19.8561 4.82386C19.8469 4.83447 19.8374 4.84482 19.8275 4.85477L15.112 9.60866C14.9969 9.72472 14.8579 9.78269 14.6952 9.78269C14.5324 9.78269 14.3935 9.72472 14.2784 9.60866C14.2231 9.55295 14.1806 9.48862 14.1506 9.4158C14.1207 9.34311 14.1057 9.26724 14.1057 9.18845L14.1059 7.8755Z'
                                            fill='#6754E2'
                                          />
                                        </svg>
                                      </span>
                                    </OverlayTrigger>
                                    <Heart
                                      stroke={"#6754E2"}
                                      fill={
                                        favCtx.favArr.includes(pos.id)
                                          ? "#6754E2"
                                          : "#ffffff"
                                      }
                                      onClick={(e) => favHandler(e, pos)}
                                      className='cursor-pointer'
                                    />
                                  </div>
                                </div>
                                <hr />
                                <div
                                  className='job-content-new p-1 m-1'
                                  onClick={() => learnmoreHandler(pos.id)}
                                >
                                  <div className='row icon-box d-flex flex-nowrap  align-items-start'>
                                    <div className='col-4 icon-desc '>
                                      <h4>
                                        {lngCtx.localLanguage
                                          ? "Industry Average Salary For Role"
                                          : JAPANESE.IndustryAverageforRole}
                                        {!!pos?.industry_avg_url && (
                                          <a
                                            href={pos?.industry_avg_url}
                                            target='_blank'
                                            rel='noreferrer'
                                          >
                                            <button className='align-items-center cursor-pointer skin_color salaryArkstrik-btn'>
                                              <OverlayTrigger
                                                placement='top'
                                                overlay={
                                                  <Tooltip>
                                                    {lngCtx.localLanguage
                                                      ? "Salary info is crowdsourced on OpenSalary. Click to view more."
                                                      : JAPANESE.SalaryInfo}
                                                  </Tooltip>
                                                }
                                              >
                                                <span className='d-inline-flex align-items-center cursor-pointer skin_color'>
                                                  *
                                                </span>
                                              </OverlayTrigger>
                                            </button>
                                          </a>
                                        )}
                                      </h4>
                                    </div>
                                    <div className='col-4 icon-desc'>
                                      <h4>
                                        {lngCtx.localLanguage
                                          ? "Company Average Salary For Role"
                                          : JAPANESE.CompanyAverageforRole}
                                        {!!pos?.company_avg_url && (
                                          <a
                                            href={pos?.company_avg_url}
                                            target='_blank'
                                            rel='noreferrer'
                                          >
                                            <button className='align-items-center cursor-pointer skin_color salaryArkstrik-btn'>
                                              <OverlayTrigger
                                                placement='top'
                                                overlay={
                                                  <Tooltip>
                                                    {lngCtx.localLanguage
                                                      ? "Salary info is crowdsourced on OpenSalary. Click to view more."
                                                      : JAPANESE.SalaryInfo}
                                                  </Tooltip>
                                                }
                                              >
                                                <span className='d-inline-flex align-items-center cursor-pointer skin_color'>
                                                  *
                                                </span>
                                              </OverlayTrigger>
                                            </button>
                                          </a>
                                        )}
                                      </h4>
                                    </div>
                                    <div className='col-4 icon-desc'>
                                      <h4>
                                        {lngCtx.localLanguage
                                          ? "Location"
                                          : JAPANESE.Location}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className='row icon-box d-flex flex-nowrap  align-items-start'>
                                    <div className='col-4 icon-desc'>
                                      <h3>
                                        {!pos.industry_avg
                                          ? "-"
                                          : `${formatNumber(
                                              parseInt(pos?.industry_avg)
                                            )} ${
                                              lngCtx.localLanguage
                                                ? "yen"
                                                : JAPANESE.yen
                                            }`}{" "}
                                      </h3>
                                    </div>
                                    <div className='col-4 icon-desc'>
                                      <h3>
                                        {!pos.company_avg
                                          ? "-"
                                          : `${formatNumber(
                                              parseInt(pos?.company_avg)
                                            )} ${
                                              lngCtx.localLanguage
                                                ? "yen"
                                                : JAPANESE.yen
                                            }`}{" "}
                                      </h3>
                                    </div>
                                    <div className='col-4 icon-desc'>
                                      <h3>{pos.location}</h3>
                                    </div>
                                  </div>
                                </div>

                                <hr onClick={() => learnmoreHandler(pos.id)} />
                                <div
                                  className={`job-detail-footer ${
                                    isMobile && "flex-column "
                                  }`}
                                  onClick={() => learnmoreHandler(pos.id)}
                                >
                                  <div className={`details-label`}>
                                    {pos?.remote_policy === "Full Remote" ? (
                                      <span>Remote</span>
                                    ) : pos?.remote_policy ===
                                      "Hybrid Remote" ? (
                                      <span>Hybrid</span>
                                    ) : (
                                      <span>On Site</span>
                                    )}
                                    {pos?.required_languages && (
                                      <span>
                                        {pos?.required_languages} Required
                                      </span>
                                    )}
                                    {pos?.role && <span>{pos?.role}</span>}
                                    {pos?.focus && <span>{pos?.focus}</span>}
                                  </div>
                                  <div className='details-learn'>
                                    <Button
                                      veriant='primary'
                                      className='btn-md without-shadow-btn'
                                      onClick={() => learnmoreHandler(pos.id)}
                                    >
                                      {lngCtx.localLanguage
                                        ? "Learn More"
                                        : JAPANESE.LearnMore}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          );
                        }
                      })
                    )}
                    {page === "jobdetail" ? null : (
                      <div className='pagination-block'>
                        <ReactPaginate
                          breakLabel='...'
                          nextLabel={
                            lngCtx.localLanguage ? "next" : JAPANESE.Next
                          }
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={5}
                          pageCount={pageCount}
                          previousLabel={
                            lngCtx.localLanguage
                              ? "previous"
                              : JAPANESE.Previous
                          }
                          renderOnZeroPageCount={null}
                          breakClassName={"break-me"}
                          marginPagesDisplayed={2}
                          subContainerClassName='pages pagination'
                          breakLinkClassName='page-link'
                          containerClassName='pagination d-flex justify-content-center'
                          pageClassName='page-item'
                          pageLinkClassName='page-link'
                          previousClassName='page-item'
                          previousLinkClassName='page-link'
                          nextClassName='page-item'
                          nextLinkClassName='page-link'
                          activeClassName='active'
                          forcePage={selectedPage}
                        />
                      </div>
                    )}
                  </Row>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <div className='d-flex justify-content-center my-1 gap-1 spinner-block'>
            <Spinner animation='grow' variant='secondary' />
            <span className=''>Loading...</span>
          </div>
        )}
        <ShareModel
          show={show}
          handleClose={() => handleClose()}
          modelDetails={modelDetails}
        />
      </div>
    </>
  );
};

export default JobListBlock;
