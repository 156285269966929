import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Nav,
  Navbar,
  
} from "react-bootstrap";
import { Heart } from "react-feather";
import { Link, useLocation, useParams } from "react-router-dom";
import "./header.scss";

import purpleLogo from "../../assets/images/transparentlogo.svg";
import Booking from "../Booking/booking";
import LanguageContext from "../Context/LanguageContext";
import { JAPANESE } from "../Constant/LangauageTranslate";
import { useDispatch, useSelector } from "react-redux";
import {
  jobRoles,
  languageChanger,
  locationAPI,
  positionById,
} from "../redux/actions";

const Header = () => {
  const lngCtx = useContext(LanguageContext);

  const location = useLocation();

  //mobile layout finder
  const [isMobile, setIsMobile] = useState(false);
  // create an event listener
  const handleResize = () => {
    if (window.innerWidth < 1000) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  const [bookingModel, setBookingModel] = useState(false);
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  const bookHandler = () => {
    setBookingModel(true);
  };

  const closeBooking = () => setBookingModel(false);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.search.selectedLang);
  const {  setLocalLanguage } = useContext(LanguageContext);
  const paramsData = useParams();
  const langChangeHandler = (e) => {
    if (e.target.checked === true) {
      dispatch(locationAPI("en"));
      dispatch(languageChanger("en"));
      dispatch(jobRoles("en"));
      setLocalLanguage(true);
      if (paramsData?.id) {
        dispatch(positionById({ id: paramsData.id, lang: "en" }));
      }
    } else {
      dispatch(locationAPI("ja"));
      dispatch(languageChanger("ja"));
      dispatch(jobRoles("ja"));
      setLocalLanguage(false);
      if (paramsData?.id) {
        dispatch(positionById({ id: paramsData.id, lang: "ja" }));
      }
    }
  };
  const languageDiv = (
    <Form>
      <div className='search-input'>
        <div
          className='form-control d-flex justify-content-end pe-0 p-0 border-0'
          style={{ backgroundColor: "transparent" }}
        >
          <div className='d-flex gap-2 align-items-center'>
            <div className='language-toggel-switch'>
              <input
                type='checkbox'
                id='lol-checkbox'
                checked={lang === "en" ? true : false}
                onChange={(e) => langChangeHandler(e)}
                style={{ backgroundColor: "transparent" }}
              />
              <label id='switch-button' htmlFor='lol-checkbox'>
                <div id='switch-button-toogel'></div>
                <div id='eng_lan'>EN</div>
                <div id='jp_lan'>JP</div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
  return (
    <>
      <Navbar expand='lg mb-5'>
        <Navbar.Brand>
          <Link to='/' className='logo-link'>
            <img alt='logo' src={purpleLogo} />
          </Link>{" "}
        </Navbar.Brand>

        <div
          className={
            isMobile ? "align-items-center d-flex flex-row-reverse gap-3" : ""
          }
        >
          <Navbar.Toggle aria-controls='basic-navbar-nav' className='ms-auto' />
          {isMobile && (
            <Link to='/favorites' className='nav-link'>
              {location.pathname !== "/" && location.pathname !== "/contect" ? (
                <Heart
                  fill='#6754E2'
                  stroke='#6754E2'
                  className='header-btn cursor-pointer'
                />
              ) : null}
            </Link>
          )}
        </div>
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ms-auto'>
            <Link to='/' className='nav-link'>
              Home
            </Link>
            <Link to='/about' className='nav-link'>
              ABOUT US
            </Link>
            <Link to='/privacypolicy' className='nav-link'>
              Privacy Policy
            </Link>
            <Link to='/contact' className='nav-link'>
              CONTACT
            </Link>
            {!isMobile && (
              <Link to='/favorites' className='nav-link'>
                {location.pathname !== "/" && location.pathname !== "/" ? (
                  <Heart
                    fill='#6754E2'
                    stroke='#6754E2'
                    className='header-btn cursor-pointer'
                  />
                ) : null}
              </Link>
            )}
            <Link className='pe-3 ps-3'>
              <Button
                className='btn-md recruiter-btn without-shadow-btn'
                onClick={bookHandler}
                style={{ width: "200px" }}
              >
                <span style={{ textDecoration: "none", color: "white" }}>
                  {lngCtx.localLanguage
                    ? "Talk to a Recruiter"
                    : JAPANESE.TalktoaRecruiter}
                </span>
              </Button>
            </Link>
            <div className='header-toggle mt-2 pe-3 ps-3 border-0 flex-end'>
              {languageDiv}
            </div>
          </Nav>
        
        </Navbar.Collapse>
      </Navbar>
      <Booking show={bookingModel} closeBooking={() => closeBooking()} />
    </>
  );
};

export default Header;
