import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import "./contactus.scss";
import { Button, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";

import { JAPANESE } from "../Constant/LangauageTranslate";
import LanguageContext from "../Context/LanguageContext";

import banner from "../../assets/images/banner.png";
import { useNavigate } from "react-router-dom";
export const ContactZapierAPi = async (obj) => {
  return await fetch(
    `https://hooks.zapier.com/hooks/catch/2899521/3osldhr?name=${obj.name}&email=${obj.email}&message=${obj.message}`
  ).then((data) => data.json());
};
const ContactUs = () => {
  const navigate = useNavigate();
  const lngCtx = useContext(LanguageContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [enable, setEnable] = useState(true);
  const { control } = useForm({});

  useEffect(() => {
    if (name && email && message) {
      setEnable(false);
    } else {
      setEnable(true);
    }
  }, [email, message, name]);
  const handleContactForm = async (e) => {
    e.preventDefault();
    const obj = {
      name: name,
      email: email,
      message: message,
    };
    try {
      const response = await ContactZapierAPi(obj);
      if (response.status === "success") {
        toast.success("Email sent");
        setName("");
        setEmail("");
        setMessage("");
        setTimeout(() => {
          navigate("/");
        }, 1000);
      }
    } catch (e) {
      toast.error("Failed to send");
    }
  };
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div className='banner-section'>
        <Row className='align-items-center'>
          <Col md={6}>
            <div className='banner-block'>
              <div className='contactus-block'>
                <h2>Contact Us</h2>
                <Form className='contact-us-form' onSubmit={handleContactForm}>
                  <Controller
                    id='name'
                    name='name'
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Form.Group className='mb-3' controlId='nametext'>
                        <Form.Label>
                          {lngCtx.localLanguage
                            ? "Your Name"
                            : JAPANESE.YourName}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          placeholder={
                            lngCtx.localLanguage
                              ? "Your Name"
                              : JAPANESE.YourName
                          }
                          {...field}
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                        />
                      </Form.Group>
                    )}
                  />
                  <Controller
                    id='email'
                    name='email'
                    rules={{
                      required: true,
                    }}
                    control={control}
                    render={({ field }) => (
                      <Form.Group className='mb-3' controlId='nametext'>
                        <Form.Label>
                          {lngCtx.localLanguage
                            ? "Email Address"
                            : JAPANESE.EmailAddress}
                        </Form.Label>
                        <Form.Control
                          type='email'
                          placeholder={
                            lngCtx.localLanguage
                              ? "Email Address"
                              : JAPANESE.EmailAddress
                          }
                          {...field}
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                        />
                      </Form.Group>
                    )}
                  />
                  <Controller
                    id='message'
                    name='message'
                    control={control}
                    rules={{
                      required: true,
                    }}
                    render={({ field }) => (
                      <Form.Group className='mb-3' controlId='nametext'>
                        <Form.Label>
                          {lngCtx.localLanguage ? "Message" : JAPANESE.Message}
                        </Form.Label>
                        <Form.Control
                          type='text'
                          as='textarea'
                          placeholder={
                            lngCtx.localLanguage ? "Message" : JAPANESE.Message
                          }
                          rows={3}
                          {...field}
                          onChange={(e) => setMessage(e.target.value)}
                          value={message}
                        />
                      </Form.Group>
                    )}
                  />
                  <div className='text-center'>
                    <Button
                      variant='primary'
                      className='rounded-pill explore-home-btn'
                      type='submit'
                      disabled={enable}
                    >
                      <div>Submit</div>
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </Col>
          {!isMobile && (
            <Col md={6}>
              <div className='banner-img'>
                <img src={banner} alt='banner-img' className='img-fluid' />
              </div>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
};

export default ContactUs;
