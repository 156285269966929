import React, { useState } from "react";

import SearchBar from "../SearchBar/searchBar";
import "./search.scss";
import "../../index.scss";

import JobListBlock from "../JoblistBlock/jobListBlock";

const Search = () => {
  const [resetPage, setResetPage] = useState(false);
  return (
    <>
      <SearchBar resetPage={resetPage} setResetPage={setResetPage} />
      <JobListBlock
        page={"jobList"}
        resetPage={resetPage}
        setResetPage={setResetPage}
      />
    </>
  );
};

export default Search;
