import React, { useEffect, useState } from "react";

const FavouritesContext = React.createContext({
  favArr: {},
  setFavArr: () => {},
});
export const FavouritesContextProvider = (props) => {
  const favoritesArr = localStorage.getItem("favorites");

  const [favArr, setFavArr] = useState(
    favoritesArr ? JSON.parse(favoritesArr) : []
  );
  useEffect(() => {
    localStorage.setItem("favorites", JSON.stringify(favArr));
  }, [favArr]);

  const contextValue = {
    favArr,
    setFavArr,
  };
  return (
    <FavouritesContext.Provider value={contextValue}>
      {props.children}
    </FavouritesContext.Provider>
  );
};



export default FavouritesContext;
