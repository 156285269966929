import React from "react";
import { Modal } from "react-bootstrap";
const ZohoBookingLink = "https://meet.esai.jobs/portal-embed#/customer/esai";
const Booking = ({ show, closeBooking }) => {
  return (
    <Modal
      className='modal-md'
      show={show}
      onHide={closeBooking}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Book Your Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <iframe
            title={"bookYourAppointment"}
            width='100%'
            height='585px'
            src={ZohoBookingLink}
            frameborder='0'
            allowfullscreen=''
          >
            {" "}
          </iframe>
       
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Booking;
