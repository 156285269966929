import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import Home from "./Components/Home/Home";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Favorites from "./Components/Favorites/favorites";
import ContactUs from "./Components/ContactUs/contactus";
import AboutUs from "./Components/AboutUs/AboutUs";
import Footer from "./Components/Footer/footer";
import Header from "./Components/Header/Header";
import MobileFooter from "./Components/Footer/MobileFooter";
import { languageChanger } from "./Components/redux/actions";

import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import "./App.scss";
import banner_shape_top from "./assets/images/banner-shape-top.svg";
import banner_shape_corner from "./assets/images/banner-shape-corner.svg";
import shape_dot from "./assets/images/shape-dot.svg";
import shape_yellow from "./assets/images/shape-yellow.svg";

import Search from "./Components/Searchpage/search";
import Jobdetail from "./Components/jobdetail/jobdetail";

function App() {
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const defaultLang = window.navigator.language;
    if (defaultLang.startsWith("en")) {
      dispatch(languageChanger("en"));
    } else {
      dispatch(languageChanger("ja"));
    }
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  return (
    <div className='App'>
      <div className='main-content'>
        <div className='banner-shape-top banner-shape'>
          <img src={banner_shape_top} alt='banner-shape-top' />
        </div>
        <div className='banner-shape-corner banner-shape'>
          <img src={banner_shape_corner} alt='banner-shape-corner' />
        </div>
        <div className='shape-dot banner-shape'>
          <img src={shape_dot} alt='banner-shape-corner' />
        </div>

        <div className='shape-dot-yellow banner-shape'>
          <img src={shape_yellow} alt='banner-shape-corner' />
        </div>
        <Container fluid>
          <Header />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='/favorites' element={<Favorites />} />
            <Route path='/jobsearch' element={<Search />} />
            <Route path='/jobdetail/:id' element={<Jobdetail />} />
            <Route path='/contact' element={<ContactUs />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          </Routes>
          {isMobile === true ? <MobileFooter /> : <Footer />}
        </Container>
      </div>
    </div>
  );
}

export default App;
