// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { pageSize, OPEN_SALARY_URL } from "../Constant/constant";

export const locationAPI = createAsyncThunk("search/location", async (obj) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + `/locations?locale=${obj}`
  );
  return response.data.data;
});
export const industriesAPI = createAsyncThunk(
  "search/industries",
  async (obj) => {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + `/industries?locale=${obj.lang}`
    );
    return response.data.data;
  }
);

export const languageChanger = createAsyncThunk(
  "search/langChange",
  async (obj) => {
    const response = obj;
    return response;
  }
);

export const searchedText = createAsyncThunk(
  "search/searchtext",
  async (obj) => {
    const response = obj;
    return response;
  }
);

export const favorites = createAsyncThunk("search/favorites", async (obj) => {
  const response = obj;
  return response;
});

export const companyList = createAsyncThunk("search/companies", async (obj) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + `/companies?locale=${obj.lang}`
  );
  return response.data;
});
export const companyListName = createAsyncThunk(
  "search/companies/names",
  async (obj) => {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL + `/companies/names?locale=${obj.lang}`
    );
    return response.data;
  }
);

export const allPositions = createAsyncThunk(
  "search/positions",
  async (obj) => {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        `/positions?locale=${obj.lang}&page=${obj.page}&size=${pageSize}` +
        (obj?.name !== "" ? `&name=${obj?.name}` : "") +
        (obj?.role !== "" ? `&role=${obj?.role}` : "") +
        (obj?.remote_policy !== ""
          ? `&remote_policy=${obj?.remote_policy}`
          : "") +
        (obj?.required_languages !== ""
          ? `&required_languages=${obj?.required_languages}`
          : "") +
        (obj?.industry !== "" ? `&industry=${obj?.industry}` : "") +
        (obj?.focus !== "" ? `&focus=${obj?.focus}` : "") +
        (obj?.company_id !== "" ? `&company_id=${obj?.company_id}` : "") +
        (obj?.company_stage !== ""
          ? `&company_stage=${obj?.company_stage}`
          : "")
    );
    return response.data;
  }
);

export const positionById = createAsyncThunk(
  "search/positionById",
  async (obj) => {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        `/positions/${obj.id}?locale=${obj.lang}`
    );
    return response.data.data;
  }
);

export const companyById = createAsyncThunk(
  "search/companyById",
  async (obj) => {
    const response = await axios.get(
      process.env.REACT_APP_API_BASE_URL +
        `/companies/${obj.id}?locale=${obj.lang}`
    );
    return response.data.data;
  }
);

export const removeCompanydetails = createAsyncThunk(
  "search/removeCompdetails",
  async (obj) => {
    const response = obj;
    return response;
  }
);

export const jobRoles = createAsyncThunk("search/jobRoles", async (obj) => {
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + `/roles?locale=${obj}`
  );
  return response.data;
});

export const openSalaryComp = createAsyncThunk(
  "search/openSalaryComp",
  async (obj) => {
    const response = await axios.get(
      OPEN_SALARY_URL + `/companies?locale=${obj.lang}`
    );
    return response.data;
  }
);

export const focusValue = createAsyncThunk("search/focusValue", async (obj) => {
  const response = obj;
  return response;
});

export const searchSlice = createSlice({
  name: "search",
  initialState: {
    locations: [],
    locationLoading: false,
    industries: [],
    selectedLang: "",
    companyList: null,
    positions: null,
    positiondetail: null,
    companydetail: null,
    loadCompDetail: false,

    searchedValue: null,
    favoritesArr: [],
    roles: [],
    focusValues: [],
    openSalCompanyList: [],
    filterValues: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(locationAPI.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.locationLoading = false;
      })
      .addCase(locationAPI.pending, (state, action) => {
        state.locationLoading = true;
      })
      .addCase(industriesAPI.fulfilled, (state, action) => {
        state.industries = action.payload;
      })
      .addCase(languageChanger.fulfilled, (state, action) => {
        state.selectedLang = action.payload;
      })
      .addCase(companyList.fulfilled, (state, action) => {
        state.companyList = action.payload;
      })
      .addCase(companyListName.fulfilled, (state, action) => {
        state.companyListName = action.payload;
      })
      .addCase(allPositions.fulfilled, (state, action) => {
        state.positions = action.payload;
      })

      .addCase(positionById.fulfilled, (state, action) => {
        state.positiondetail = action.payload;
      })
      .addCase(companyById.pending, (state, action) => {
        state.companydetail = null;
        state.loadCompDetail = true;
      })
      .addCase(companyById.fulfilled, (state, action) => {
        state.companydetail = action.payload;
        state.loadCompDetail = false;
      })
      .addCase(searchedText.fulfilled, (state, action) => {
        state.searchedValue = action.payload;
      })
      .addCase(favorites.fulfilled, (state, action) => {
        state.favoritesArr = action.payload;
      })
      .addCase(removeCompanydetails.fulfilled, (state, action) => {
        state.companydetail = action.payload;
      })
      .addCase(jobRoles.fulfilled, (state, action) => {
        state.roles = action.payload.data.roles;
        state.focusValues = action.payload.data.focuses;
      })
      .addCase(focusValue.fulfilled, (state, action) => {})
      .addCase(openSalaryComp.fulfilled, (state, action) => {
        state.openSalCompanyList = action.payload;
      });
  },
});

export default searchSlice.reducer;
