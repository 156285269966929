import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import banner from "../../assets/images/banner.png";
import LanguageContext from "../Context/LanguageContext";
import { JAPANESE } from "../Constant/LangauageTranslate";

const AboutUs = () => {
  const lngCtx = useContext(LanguageContext);
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    window.addEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <div className='banner-section'>
        <Row className='align-items-center'>
          <Col md={6}>
            <div className='banner-block'>
              <div className='contactus-block'>
                <h2>About Us</h2>
                <div className='p-3'>
                  {lngCtx.localLanguage ? (
                    <h5>
                      ESAI.jobs is the job portal of{" "}
                      <a
                        href='https://executivesearch.ai'
                        target='_blank'
                        rel='noreferrer'
                        style={{ textDecoration: "none", color: "#1d34c9" }}
                      >
                        ExecutiveSearch.AI
                      </a>
                      , a tech-first recruiting firm in Shibuya, Tokyo. We
                      partner with the top tech companies in Japan to find
                      talented people like yourself. Speak with one of our
                      recruiters on your own schedule. We are happy to offer a
                      confidential career consultation. If you work in tech in
                      Japan, you deserve our white glove treatment and industry
                      expertise.
                    </h5>
                  ) : (
                    <h5>{JAPANESE.AboutusDescription1}</h5>
                  )}
                  <div className='d-flex justify-content-center'>
                    <Button className='btn-md recruiter-btn without-shadow-btn mt-2 '>
                      <a
                        href='https://meet.esai.jobs/#/customer/esai'
                        target='_blank'
                        rel='noreferrer'
                        style={{ textDecoration: "none", color: "white" }}
                      >
                        {lngCtx.localLanguage
                          ? "Talk to a Recruiter"
                          : JAPANESE.TalktoaRecruiter}
                      </a>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          {!isMobile && (
            <Col md={6}>
              <div className='banner-img'>
                <img src={banner} alt='banner-img' className='img-fluid' />
              </div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default AboutUs;
