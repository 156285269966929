import React, { useState } from "react";

const LanguageContext = React.createContext({
  localLanguage: {},
  setLocalLanguage: () => {},
});
export const LanguageContextProvider = (props) => {
  const [localLanguage, setLocalLanguage] = useState(true);

  const contextValue = {
    localLanguage,
    setLocalLanguage,
  };
  return (
    <LanguageContext.Provider value={contextValue}>
      {props.children}
    </LanguageContext.Provider>
  );
};



export default LanguageContext;
