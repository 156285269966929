import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Booking from "../Booking/booking";

import banner from "../../assets/images/banner.png";
import "./home.scss";
import { useNavigate } from "react-router";

import LanguageContext from "../Context/LanguageContext";
import { JAPANESE } from "../Constant/LangauageTranslate";

const Home = () => {
  const lngCtx = useContext(LanguageContext);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const jobHandler = () => {
    navigate("/jobsearch");
  };
  const handleResize = () => {
    if (window.innerWidth < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    window.addEventListener("resize", handleResize);
  }, []);
  const [bookingModel, setBookingModel] = useState(false);
  const bookHandler = () => {
    setBookingModel(true);
  };
  const closeBooking = () => setBookingModel(false);
  const tilteMainEN = "Browse Hundreds of the Top Tech Jobs in Japan";
  return (
    <>
      <div className='banner-section'>
        <Row className='align-items-center '>
          <Col md={6}>
            <div className='banner-block'>
              <div className='banner-info'>
                <h2>
                  {lngCtx.localLanguage
                    ? isMobile
                      ? tilteMainEN.toUpperCase()
                      : tilteMainEN
                    : JAPANESE.TitleMain}
                </h2>
                <p>
                  {lngCtx.localLanguage ? "We are " : JAPANESE.Description1}
                  <a
                    href='https://executivesearch.ai'
                    target='_blank'
                    style={{ textDecoration: "none", color: "#1d34c9" }}
                    rel='noreferrer'
                  >
                    ExecutiveSearch.AI
                  </a>
                  {lngCtx.localLanguage
                    ? `. We are different. We have deep relationships with the top tech
                  companies in Japan. Our expert recruiters can help you make
                  your best career decision.`
                    : JAPANESE.Description2}
                </p>

                <Button
                  variant='primary'
                  className='rounded-pill explore-home-btn m-1'
                  onClick={() => jobHandler()}
                >
                  EXPLORE JOBS
                </Button>
                <Button
                  variant='primary'
                  className='rounded-pill explore-home-btn m-1'
                  onClick={bookHandler}
                >
                  TALK TO A RECRUITER
                </Button>
              </div>
            </div>
          </Col>
          {!isMobile && (
            <Col md={6}>
              <div className='banner-img'>
                <img src={banner} alt='banner-img' className='img-fluid' />
              </div>
            </Col>
          )}
        </Row>
      </div>
      <Booking show={bookingModel} closeBooking={() => closeBooking()} />
    </>
  );
};

export default Home;
