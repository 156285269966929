import React, { useState } from "react";
import powered_by from "../../assets/images/powered_by.svg";
import helpCircle from "../../assets/images/help-circle.svg";
import "./footer.scss";

const MobileFooter = () => {
  const [isView, setIsView] = useState(false);
  return (
    <div className='footer-block d-flex justify-content-between'>
      <div className='powered_by'>
        <p className='text-start'>
          <span className='me-1'>Salary info is crowdsourced on</span>
          <a href='https://opensalary.jp/' target='_blank' rel='noreferrer'>
            <img src={powered_by} alt='powered-by' />
          </a>
        </p>
      </div>
      <div className='footer-notice m-2' onClick={() => setIsView(!isView)}>
        {isView && (
          <p>
            All product names, logos, and brands are property of their
            respective owners. All company, product and service names used are
            for identification purposes only. Use of these names, logos, and
            brands does not imply endorsement.
          </p>
        )}

        {!isView && (
          <p>
            <img src={helpCircle} alt='banner-shape-corner' />
          </p>
        )}
      </div>
    </div>
  );
};

export default MobileFooter;
