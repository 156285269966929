export const JAPANESE = {
  TitleMain: `Browse Hundreds of the Top Tech Jobs in Japan`,
  Description1: `私たちは`,
  Description2: `です。一般的なエージェントとは異なり、日本トップのテックカンパニーと深い関係性を築いています。
専門性の高いリクルーターがベストなキャリアをサポートします。`,
  ExploreJobs: `求人を見る`,
  SalaryinfoiscrowdsourcedonOpensalary: `給与情報はOpenSalaryでクラウドソーシングされています。`,
  FooterDescription: `すべての社名、製品名、サービス名、ロゴ、ブランド、またすべての登録商標または未登録商標は、識別の目的でのみ使用されているものであり、それぞれの所有者の独占的な財産となります。サードパーティに関わるブランド、名称、ロゴ、その他の情報、画像、資料の使用は、それらを推奨することを意味するものではありません。`,
  HOME: `HOME`,
  ABOUTUS: `ABOUT US`,
  CONTACT: `CONTACT`,
  PRIVACYPOLICY: `PRIVACY POLICY`,
  AboutUs: `About Us`,
  AboutusDescription1: `ESAI.jobsは、東京・渋谷にあるテックファーストのエージェントであるExecutiveSearch.AIの求人ポータルサイトです。
日本有数のハイテク企業と提携し、優秀な人材にリーチしております。
スケジュールに合わせて、リクルーターとお話しください。秘密厳守でキャリア相談をお受けします。
どこのエージェントよりも業界の専門知識が豊富で、キャリア相談のより良い体験を提供できると信じています。`,
  TalktoaRecruiter: `リクルーターと話す`,
  ContactUs: `お問い合わせ`,
  YourName: `氏名`,
  EmailAddress: `メールアドレス`,
  Message: `メッセージ`,
  SearchJobs: "求人を探す",
  JobCategory: "職種",
  LanguageRequired: "言語から探す",
  RemotePolicy: "リモートワークから探す",
  JapaneseRequired: "日本語必須",
  EnglishRequired: "英語必須",
  BilingualRequired: "バイリンガル（日英）必須",
  Clear: "クリア",
  ClearAll: "Clear All",
  JobsForYou: "Jobs For You",
  CopySearchParameters: "検索条件をコピーする",
  Speciality: "専門スキルから探す",
  CompanyStage: "企業の大きさ",
  Company: "企業名から探す",
  Industry: "業界",
  LearnMore: "Learn More",
  CopyJobLink: "この求人のURLをコピーする",
  Previous: "前へ",
  Next: "次へ",
  SalaryInfo: "OpenSalaryでクラウドソーシングされている給与情報はこちら",
  yen: "円",
  Choose: "選択",
  Back: "戻る",
  SharethisjobonbelowPlatform: "この求人をシェア",
  IndustryAverageforRole: "この業界及び職種の平均給与",
  CompanyAverageforRole: "この企業及び職種の平均給与",
  Location: "勤務地",
  CompanyMission: "企業のミッション",
  CultureandBenefits: "企業の文化と福利厚生",
  RecentArticles: "最新記事",
  NotesfromExecutiveSearchAI: "ExecutiveSearch.AIからのコメント",
  TechStack: "技術スタック",
  Favorites: "保存",
};
