import React, { useContext } from "react";

import {
  Button,
  Col,
  Row,
 
} from "react-bootstrap";
import { ArrowLeft } from "react-feather";
import { useNavigate } from "react-router";
import JobListBlock from "../JoblistBlock/jobListBlock";

import LanguageContext from "../Context/LanguageContext";
import { JAPANESE } from "../Constant/LangauageTranslate";

const Favorites = () => {
  const navigate = useNavigate();
  const lngCtx = useContext(LanguageContext);
  return (
    <>
     
      <div>
        <Row className='mt-3'>
          <Col sm={12}>
            <div className='py-1'>
              <Button
                variant='link'
                className='back-btn skin_color p-0 shadow-none'
                onClick={() => navigate("/jobsearch")}
              >
                <ArrowLeft />
                {lngCtx.localLanguage ? "Back" : JAPANESE.Back}
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <h1 className='page-title mb-3'>
        {lngCtx.localLanguage ? "Favorites" : JAPANESE.Favorites}
      </h1>
      <JobListBlock page='favorites' />

    </>
  );
};

export default Favorites;
