import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";

import SearchBar from "../SearchBar/searchBar";
import "./jobdetail.scss";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  allPositions,
  companyById,
  companyList,
  positionById,
} from "../redux/actions";
import powered_by from "../../assets/images/powered_by.svg";
import { ArrowLeft } from "react-feather";
import { Heart } from "react-feather";
import ShareModel from "../ShareModel/shareModel";
import JobListBlock from "../JoblistBlock/jobListBlock";

import { formatNumber } from "../Constant/constant";
import Booking from "../Booking/booking";
import FavouritesContext from "../Context/FavouritesContext";
import LanguageContext from "../Context/LanguageContext";
import { JAPANESE } from "../Constant/LangauageTranslate";

const Jobdetail = () => {
  const favCtx = useContext(FavouritesContext);
  const lngCtx = useContext(LanguageContext);
  const paramsData = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const favoritesArr = localStorage.getItem("favorites");

  const companies = useSelector((state) => state.search.companyList);
  const positions = useSelector((state) => state.search.positions);
  const lang = useSelector((state) => state.search.selectedLang);
  const positiondetail = useSelector((state) => state.search.positiondetail);
  const companydetail = useSelector((state) => state.search.companydetail);
  const searchedVal = useSelector((state) => state.search.searchedValue);

  const [show, setShow] = useState(false);
  const [viewDevice, setViewDevice] = useState("");
  const [modelDetails, setModelDetails] = useState({ logo: "", name: "" });
  const [bookingModel, setBookingModel] = useState(false);
  const [posIsFav, setPosIsFav] = useState(false);

  const classWithType = {
    heading_1: "h3",
    heading_2: "h4",
    heading_3: "h5",
  };

  const myParam = useLocation().search;

  const filterdata = new URLSearchParams(myParam);

  const jobObj = {
    lang: lang,
    name: filterdata.get("name")
      ? filterdata.get("name")
      : !searchedVal?.search && !location?.state?.searchvalue
      ? ""
      : location?.state?.searchvalue
      ? location?.state?.searchvalue
      : searchedVal?.search,
    page: 1,
    role: filterdata.get("role")
      ? filterdata.get("role")
      : searchedVal?.role
      ? searchedVal?.role
      : "",
    remote_policy: filterdata.get("remote_policy")
      ? filterdata.get("remote_policy")
      : searchedVal?.remote_policy
      ? searchedVal?.remote_policy
      : "",
    required_languages: filterdata.get("required_languages")
      ? filterdata.get("required_languages")
      : searchedVal?.required_languages
      ? searchedVal?.required_languages
      : "",
    industry: "",
    focus: "",
    company_id: "",
    company_stage: "",
  };
  const handleResize = () => {
    if (window.innerWidth < 768) {
      setViewDevice("mobile");
    } else {
      setViewDevice("desktop");
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const obj = {
      id: paramsData.id,
      lang: lang,
      name: searchedVal?.search,
      page: 1,
    };
    dispatch(positionById(obj));
    if (!companies) {
      dispatch(companyList(obj));
    }
    if (!positions) {
      dispatch(allPositions(jobObj));
    }
    if (JSON.parse(favoritesArr)?.includes(paramsData?.id)) {
      setPosIsFav(true);
    } else {
      setPosIsFav(false);
    }
  }, [paramsData.id, lang]);

  useMemo(() => {
    if (positiondetail) {
      const compObj = {
        id: positiondetail?.company_id,
        lang: lang,
      };
      dispatch(companyById(compObj));
    }
  }, [positiondetail, lang]);

  const getStyleObject = (annotation) => {
    return Object.entries(annotation)
      ?.map(([key, val]) => {
        switch (key) {
          case "bold":
            return {
              fontWeight: val === true ? "bold" : "normal",
            };
          case "italic":
            return {
              fontStyle: val === true ? "italic" : "normal",
            };
          case "strikethrough":
            return {
              textDecoration: val === true ? "line-through" : "none",
            };
          case "underline":
            return {
              textDecoration: val === true ? "underline" : "none",
            };
          case "code":
            return {
              fontFamily: val === true ? 'Consolas,"courier new"' : "inherit",
            };
          case "color":
            if (val.includes("background")) {
              return {
                backgroundColor: val.split("_")[0],
              };
            } else {
              return {
                color: val,
              };
            }

          default:
            return {};
        }
      })
      .reduce((final, obj, i) => {
        return {
          ...final,
          ...obj,
        };
      }, {});
  };

  const modelHandler = (e, selLogo, selName, selIndustry) => {
    e.preventDefault();
    setShow(true);
    setModelDetails({
      logo: selLogo,
      name: selName,
      industry: selIndustry,
    });
  };

  const handleClose = () => setShow(false);

  const bookHandler = () => {
    setBookingModel(true);
  };

  const closeBooking = () => setBookingModel(false);

  const favHandler = (e) => {
    e.preventDefault();
    const stored = localStorage.getItem("favorites") ?? "[]";
    let filterArr = [...new Set([...favCtx.favArr, ...JSON.parse(stored)])];
    let isEle =
      filterArr.length > 0 && filterArr.find((x) => x === paramsData.id);
    if (isEle) {
      let Ar1 = filterArr.filter((x) => x !== paramsData.id);
      favCtx.setFavArr(Ar1);
      localStorage.setItem("favorites", JSON.stringify(Ar1));
      setPosIsFav(false);
    } else {
      filterArr.push(paramsData.id);
      favCtx.setFavArr(filterArr);
      localStorage.setItem("favorites", JSON.stringify(filterArr));
      setPosIsFav(true);
    }
  };

  const renderChildren = (x, i) => {
    if (x?.type === "paragraph") {
      return (
        <div className="job-description position-relative" key={i}>
          <p key={`p-tech-${i}`} className="d-block detail-text text-start">
            {x?.paragraph?.rich_text?.map((comp, compi) => {
              return comp?.text?.content.split(",").map((val, i) => (
                <span key={`p-${i}`} style={getStyleObject(comp?.annotations)}>
                  {val.trim()}
                  <br />
                </span>
              ));
            })}
          </p>
        </div>
      );
    }
    if (x?.type === "bulleted_list_item") {
      return (
        <ul className="job-description position-relative" key={i}>
          <li className="text-start detail-text">
            {x?.bulleted_list_item?.rich_text?.map((val, i) =>
              renderText(val, i, "", x?.children)
            )}
          </li>
        </ul>
      );
    }
  };

  const renderText = (val, i, type = "", children = []) => {
    return !val.href ? (
      <span key={i} style={getStyleObject(val?.annotations)}>
        {val.text?.content}
        {Array.isArray(children)
          ? children.map((x, i) => {
              return renderChildren(x, i);
            })
          : null}
      </span>
    ) : (
      <a
        href={val.href}
        className="detail-text"
        target="_blank"
        rel="noreferrer"
        key={i}
      >
        {val.text?.content}
      </a>
    );
  };

  const RenderPara = (array) => {
    const inner = array?.paragraph?.rich_text.map((value, i) => {
      const { annotations, text: { content, link } } = value;
      const style = getStyleObject(annotations);
      style["whiteSpace"] = "pre-wrap";
      if(link === null) {
        return (
          <span
            key={`p-note-${i}`}
            className="card-desc detail-text"
            style={style}
          >
            {content}
          </span>
        );
      } else {
        return (
          <a
            href={link.url}
            className="detail-text"
            target="_blank"
            rel="noreferrer"
            key={i}
          >
            {content}
          </a>
        )
      }
    });
    return <p className="card-desc detail-text">{inner}</p>;
  };
  const footerDiv = (
    <div className="job-title-footer">
      <Row className="">
        <Col>
          <div className="powered_by-jobdetail">
            <p className="text-start">
              <span className="me-1">Salary info is crowdsourced on</span>
              <a href="https://opensalary.jp/">
                <img src={powered_by} alt="powered-by" />
              </a>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );

  const NotesIndex =
    companydetail?.notes && companydetail?.notes[0]?.type === "paragraph"
      ? true
      : false;
  const CultureIndex =
    companydetail?.culture_and_benefits &&
    companydetail?.culture_and_benefits[0]?.type === "paragraph"
      ? true
      : false;

  const findAndReplaceLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlRegex);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <a
            className="detail-text"
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>
        );
      } else {
        return <p key={index}>{part}</p>;
      }
    });
  };

  const renderJobDetailRecursively = (children) => {
    return Array.isArray(children) ? (
      children.map((child, index) => {
        return showDynamicUI(child, index);
      })
    ) : (
      <></>
    );
  };

  const showDynamicUI = (info, index) => {
    let type = info?.type;
    let children = info?.children;

    switch (type) {
      case "bulleted_list_item":
        return (
          <ul className="card-list m-0" key={"bullet_" + index}>
            <li className="detail-text text-start">
              {info[type]?.rich_text?.map((val, i) => renderText(val, i))}
            </li>
            {renderJobDetailRecursively(children)}
          </ul>
        );

      case "numbered_list_item":
        return (
          <ul className="card-list m-0" key={"bullet_" + index}>
            <li className="detail-text text-start">
              {info[type]?.rich_text?.map((val, i) => renderText(val, i))}
            </li>
            {renderJobDetailRecursively(children)}
          </ul>
        );

      case "paragraph":
        return (
          <div
            className="job-description position-relative"
            key={"para_" + index}
          >
            <p className="d-block card-desc detail-text">
              {RenderPara(info)}
              {renderJobDetailRecursively(children)}
            </p>
          </div>
        );
      case "image":
        return (
          <div className="" key={"img_" + index}>
            <img
              src={info?.image?.external?.url}
              alt=""
              style={{ width: "100%" }}
            />
            {renderJobDetailRecursively(children)}
          </div>
        );
      case "heading_1":
      case "heading_2":
      case "heading_3":
        return (
          <div
            className="job-description position-relative mt-4"
            key={"head_" + index}
          >
            {info?.[info.type]?.rich_text?.map((val, i) => (
              <span
                key={`h5-${i}`}
                className={`d-block ${classWithType[info?.type]}`}
              >
                {renderText(val, i, info?.type)}
              </span>
            ))}
            {renderJobDetailRecursively(children)}
          </div>
        );
      case "table":
        return (
          <div className="position-relative mt-4 w-100" key={"tbl_" + index}>
            <table
              className="table table-bordered border-black"
              style={{
                tableLayout: "fixed",
              }}
            >
              <tbody>{renderJobDetailRecursively(children)}</tbody>
            </table>
          </div>
        );
      case "table_row":
        return (
          <tr
            style={{
              color: "#263b5e",
              fontFamily: "sans-serif",
            }}
            key={"tr_" + index}
          >
            {Array.isArray(info?.[type]?.cells) ? (
              <>
                {info?.[type]?.cells?.map((cell, i) => (
                  <React.Fragment key={"cell_" + i}>
                    {i === 0 ? (
                      <th
                        scope="row"
                        className={"p-2 text-center"}
                        style={{
                          width: "120px",
                        }}
                        valign="middle"
                      >
                        {Array.isArray(cell) ? (
                          cell.map((sub, ind) => {
                            return renderText(sub, ind);
                          })
                        ) : (
                          <></>
                        )}
                      </th>
                    ) : (
                      <td
                        className={`p-2`}
                        style={{
                          whiteSpace: "break-spaces",
                          wordBreak: "break-word",
                          fontSize: "16px",
                          color: "#465875",
                        }}
                      >
                        {Array.isArray(cell) ? (
                          cell.map((sub, ind) => {
                            return renderText(sub, ind);
                          })
                        ) : (
                          <></>
                        )}
                      </td>
                    )}
                  </React.Fragment>
                ))}

                {renderJobDetailRecursively(children)}
              </>
            ) : (
              <></>
            )}
          </tr>
        );

      default:
        return <></>;
    }
  };

  const jobDetailDiv = (
    <div
      className={`job-detail-block-spacer ${
        viewDevice === "mobile" ? "mb-4" : ""
      }`}
    >
      <div className="job-detail-block">
        <Row>
          <Col md={6}>
            <div className="job-header jobdetail-header d-block">
              <div className="job-title">
                <div className="fav-detail-page position-absolute">
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        {favCtx.favArr?.includes(paramsData?.id)
                          ? "Remove from Favorites"
                          : "Add to Favorites"}
                      </Tooltip>
                    }
                  >
                    <Heart
                      stroke={
                        favCtx.favArr?.includes(paramsData?.id)
                          ? "#6754E2"
                          : "#C6C6C6"
                      }
                      fill={
                        favCtx.favArr?.includes(paramsData?.id)
                          ? "#6754E2"
                          : "#ffffff"
                      }
                      onClick={(e) => favHandler(e)}
                      className="cursor-pointer"
                    />
                  </OverlayTrigger>
                </div>
                {companydetail?.logo && (
                  <div className="job-logo job-logo-detail">
                    <img src={companydetail?.logo} alt="logo" />
                  </div>
                )}
                <div className="title-desc">
                  <h3>{positiondetail?.company}</h3>
                  <h5>{positiondetail?.name}</h5>
                  <ul>
                    <li>{companydetail?.industry}</li>
                  </ul>
                </div>
                <Row className="justify-content-center">
                  {positiondetail?.focus ? (
                    <Col xs={4}>
                      <div className="detail-chip">{positiondetail?.focus}</div>
                    </Col>
                  ) : null}
                  <Col xs={4}>
                    <div className="detail-chip">{positiondetail?.role}</div>
                  </Col>
                  <Col xs={4}>
                    <div className="detail-chip">
                      {positiondetail?.remote_policy}
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs={12} sm={10}>
                    <Button
                      className="btn-md recruiter-btn without-shadow-btn"
                      onClick={() => bookHandler()}
                    >
                      {lngCtx.localLanguage
                        ? "Talk to a Recruiter"
                        : JAPANESE.TalktoaRecruiter}
                    </Button>
                  </Col>
                  <Col xs={12} sm={2}>
                    <div
                      className="sharebtn w-100 h-100"
                      onClick={(e) =>
                        modelHandler(
                          e,
                          companydetail?.logo,
                          positiondetail?.name,
                          companydetail?.industry
                        )
                      }
                    >
                      <Button
                        variant="link"
                        className="p-0 m-auto shadow-none line-height"
                      >
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M7.43267 3.59896C7.48573 3.5459 7.52653 3.48463 7.55534 3.41528C7.58401 3.34605 7.59841 3.27379 7.59841 3.19875C7.59841 3.04249 7.54321 2.90909 7.43267 2.79855C7.32214 2.68802 7.18874 2.63281 7.03247 2.63281H2.12699C1.6582 2.63281 1.25826 2.79855 0.926778 3.1299C0.595428 3.46138 0.429688 3.86133 0.429688 4.33012V16.0272C0.429688 17.1587 0.995499 17.7245 2.12699 17.7245H13.8241C14.9556 17.7245 15.5214 17.1587 15.5214 16.0272V11.1217C15.5214 10.9655 15.4662 10.8321 15.3557 10.7215C15.2451 10.611 15.1117 10.5558 14.9555 10.5558C14.8804 10.5558 14.8082 10.5702 14.7389 10.5989C14.6696 10.6277 14.6083 10.6685 14.5553 10.7215C14.5022 10.7746 14.4614 10.8359 14.4326 10.9052C14.4039 10.9744 14.3895 11.0467 14.3895 11.1217V16.0272C14.3895 16.4042 14.201 16.5926 13.8241 16.5926H2.12699C1.75004 16.5926 1.56157 16.4042 1.56157 16.0272V4.33012C1.56157 3.95317 1.75005 3.7647 2.12699 3.7647H7.03247C7.10751 3.7647 7.17977 3.75029 7.24899 3.72162C7.31835 3.69294 7.37962 3.65201 7.43267 3.59896Z"
                            fill="#6754E2"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14.1059 7.8755C14.0934 7.87298 14.081 7.87046 14.0685 7.86794C12.3108 7.51578 10.681 7.88372 9.17881 8.97206C7.64205 10.0855 6.51962 11.7476 5.81162 13.9589C5.73899 14.1854 5.67071 14.4179 5.60689 14.6561C5.58624 14.7643 5.53637 14.8605 5.45717 14.9444C5.44743 14.9549 5.43717 14.9651 5.42664 14.9748C5.32033 15.074 5.19468 15.1259 5.04957 15.1304C5.0322 15.131 5.01483 15.1309 4.99746 15.1301C4.90089 15.1256 4.81287 15.0999 4.73366 15.0528C4.66564 15.0142 4.60749 14.9625 4.55946 14.8975C4.51117 14.8353 4.47749 14.7669 4.45815 14.6927C4.44065 14.6299 4.43421 14.5657 4.43907 14.4999C4.44578 11.0765 5.39232 8.16436 7.27867 5.76364C9.13921 3.39597 11.4149 2.16257 14.1057 2.0641V0.869139C14.1057 0.705062 14.1632 0.564992 14.2784 0.448929C14.3935 0.332866 14.5324 0.274902 14.6952 0.274902C14.759 0.274902 14.8211 0.285116 14.8816 0.30541C14.9422 0.325704 14.9978 0.355149 15.0489 0.393751L19.7644 3.95917C19.8946 4.05759 19.9712 4.18811 19.9942 4.35045C20.0173 4.51294 19.9799 4.65977 19.8823 4.7911C19.8739 4.80237 19.8652 4.81325 19.8561 4.82386C19.8469 4.83447 19.8374 4.84482 19.8275 4.85477L15.112 9.60866C14.9969 9.72472 14.8579 9.78269 14.6952 9.78269C14.5324 9.78269 14.3935 9.72472 14.2784 9.60866C14.2231 9.55295 14.1806 9.48862 14.1506 9.4158C14.1207 9.34311 14.1057 9.26724 14.1057 9.18845L14.1059 7.8755Z"
                            fill="#6754E2"
                          />
                        </svg>
                      </Button>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="">
                  <Col md={6}>
                    <div className="job-card">
                      <h3 className="card-title">
                        {lngCtx.localLanguage
                          ? "Industry Average Salary For Role"
                          : JAPANESE.IndustryAverageforRole}
                        {!!positiondetail?.industry_avg_url && (
                          <a
                            href={positiondetail?.industry_avg_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button className="align-items-center cursor-pointer skin_color salaryArkstrik-btn">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    {lngCtx.localLanguage
                                      ? "Salary info is crowdsourced on OpenSalary. Click to view more."
                                      : JAPANESE.SalaryInfo}
                                  </Tooltip>
                                }
                              >
                                <span className="d-inline-flex align-items-center cursor-pointer skin_color">
                                  *
                                </span>
                              </OverlayTrigger>
                            </button>
                          </a>
                        )}
                      </h3>
                      <div className="text-start">
                        <label className="fw-semibold mcolor cstm-label">
                          {!positiondetail?.industry_avg
                            ? "-"
                            : `${formatNumber(
                                parseInt(positiondetail?.industry_avg)
                              )} ${
                                lngCtx.localLanguage ? "yen" : JAPANESE.yen
                              }`}
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="job-card">
                      <h3 className="card-title">
                        {lngCtx.localLanguage
                          ? "Company Average Salary For Role"
                          : JAPANESE.CompanyAverageforRole}
                        {!!positiondetail?.company_avg_url && (
                          <a
                            href={positiondetail?.company_avg_url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button className="align-items-center cursor-pointer skin_color salaryArkstrik-btn">
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip>
                                    {lngCtx.localLanguage
                                      ? "Salary info is crowdsourced on OpenSalary. Click to view more."
                                      : JAPANESE.SalaryInfo}
                                  </Tooltip>
                                }
                              >
                                <span className="d-inline-flex align-items-center cursor-pointer skin_color">
                                  *
                                </span>
                              </OverlayTrigger>
                            </button>
                          </a>
                        )}
                      </h3>
                      <div className="text-start">
                        <label className="fw-semibold mcolor cstm-label">
                          {!positiondetail?.company_avg
                            ? "-"
                            : `${formatNumber(
                                parseInt(positiondetail?.company_avg)
                              )} ${
                                lngCtx.localLanguage ? "yen" : JAPANESE.yen
                              }`}
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="">
                  <Col>
                    <div className="teck_skill-block job-card">
                      <h3 className="card-title">
                        <u>
                          {lngCtx.localLanguage
                            ? "Location"
                            : JAPANESE.Location}
                        </u>
                      </h3>
                      <div className="d-flex gap-2 flex-wrap justify-content-start">
                        <div className="detail-chip">
                          {positiondetail?.location}
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="">
                  <Col>
                    <div className="job-card">
                      <h3 className="card-title">
                        <u>
                          {lngCtx.localLanguage
                            ? "Company Mission"
                            : JAPANESE.CompanyMission}
                        </u>
                      </h3>
                      {companydetail?.summary ? (
                        companydetail?.summary.map((comp, compi) => showDynamicUI(comp, compi))
                      ) : (
                        <Spinner animation="border" />
                      )}
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="">
                  <Col>
                    <div className="job-card">
                      <h3 className="card-title">
                        <u>
                          {lngCtx.localLanguage
                            ? "Culture and Benefits"
                            : JAPANESE.CultureandBenefits}
                        </u>
                      </h3>
                      {companydetail?.culture_and_benefits ? (
                        companydetail?.culture_and_benefits?.map((comp, compi) => showDynamicUI(comp, compi))
                      ) : (
                        <Spinner animation="border" />
                      )}
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="">
                  <Col>
                    <div className="job-card">
                      <h3 className="card-title recent-article">
                        <u>
                          {lngCtx.localLanguage
                            ? "Recent Articles"
                            : JAPANESE.RecentArticles}
                        </u>
                      </h3>
                      <>
                        {companydetail?.recent_articles ? (
                          companydetail?.recent_articles?.map((comp, compi) => (
                            <div key={compi}>
                              {comp?.type === "table_row" && (
                                <p
                                  className="text-start recent-article"
                                  key={compi}
                                >
                                  {comp.table_row.cells[2][0]?.plain_text ===
                                  "Website Name"
                                    ? null
                                    : `${comp.table_row.cells[2][0]?.plain_text} /`}{" "}
                                  <a
                                    className="detail-text"
                                    href={comp.table_row.cells[0][0]?.href}
                                  >
                                    {comp.table_row.cells[1][0]?.plain_text ===
                                    "Title of Article"
                                      ? null
                                      : comp.table_row.cells[1][0]?.plain_text}
                                  </a>
                                </p>
                              )}
                            </div>
                          ))
                        ) : (
                          <Spinner animation="border" />
                        )}
                      </>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="">
                  <Col>
                    <div className="job-card">
                      <h3 className="card-title">
                        <u>
                          {lngCtx.localLanguage
                            ? "Notes From ExecutiveSearch.AI"
                            : JAPANESE.NotesfromExecutiveSearchAI}
                        </u>
                      </h3>

                      {companydetail?.notes ? (
                        companydetail?.notes?.map((comp, compi) => showDynamicUI(comp, compi))
                      ) : (
                        <Spinner animation="border" />
                      )}
                    </div>
                  </Col>
                </Row>
                <hr />
                <Row className="">
                  <Col>
                    <div className="teck_skill-block job-card">
                      {positiondetail?.role === "Software Engineer" && (
                        <h3 className="card-title">
                          <u>
                            {lngCtx.localLanguage
                              ? "Tech Stack"
                              : JAPANESE.TechStack}
                          </u>
                        </h3>
                      )}

                      {positiondetail?.role === "Software Engineer" ? (
                        companydetail?.tech_stacks ? (
                          companydetail?.tech_stacks?.map((tech) => {
                            if (positiondetail?.focus) {
                              if (
                                `${positiondetail?.focus} Tech Stack` ===
                                tech.area
                              ) {
                                return tech.stack.map((x, i) => showDynamicUI(x, i));
                              }
                            }
                          })
                        ) : (
                          <Spinner animation="border" />
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              {viewDevice === "desktop" && footerDiv}
            </div>
          </Col>

          <Col md={6}>
            {positiondetail &&
              positiondetail?.description?.map((info, detaili) => (
                <div key={`p-${detaili}`}>
                  <Row>
                    <Col>
                      <div className="job-card">
                        {showDynamicUI(info, detaili)}
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            {positiondetail?.description?.length === 0 && (
              <Row className="text-center">
                <Col>No Data Found</Col>
              </Row>
            )}
          </Col>
          {viewDevice === "mobile" && (
            <div className="mt-3 d-flex justify-content-center">
              {footerDiv}
            </div>
          )}
        </Row>
      </div>
    </div>
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <SearchBar />
      <div></div>
      {positiondetail ? (
        <>
          <div>
            <Row className="mt-3">
              <Col sm={12}>
                <div className="py-1">
                  <Button
                    variant="link"
                    className="back-btn skin_color p-0 shadow-none"
                    onClick={() => navigate(-2)}
                  >
                    <ArrowLeft />

                    {lngCtx.localLanguage ? "Back" : JAPANESE.Back}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
          <Row className="mb-4 job-details-row">
            <Col xl={4} lg={3}>
              <div className="job-list-wrapper">
                <Row className="m-0">
                  {viewDevice === "desktop" ? (
                    <JobListBlock page="jobdetail" />
                  ) : (
                    jobDetailDiv
                  )}
                </Row>
              </div>
            </Col>

            <Col xl={8} lg={9}>
              {viewDevice === "desktop" ? (
                jobDetailDiv
              ) : (
                <JobListBlock page="jobdetail" />
              )}
            </Col>
          </Row>
        </>
      ) : (
        <div className="d-flex justify-content-center my-1 gap-1 spinner-block">
          <Spinner animation="grow" variant="secondary" />
          <span className="">Loading...</span>
        </div>
      )}

      <ShareModel
        show={show}
        handleClose={() => handleClose()}
        modelDetails={modelDetails}
      />
      <Booking show={bookingModel} closeBooking={() => closeBooking()} />
    </div>
  );
};

export default Jobdetail;
