import React from 'react';
import { Modal } from 'react-bootstrap';
import '../jobdetail/jobdetail.scss';
import './sharemodal.scss';
import social_fb from '../../assets/images/facebook.svg';
import social_twitter from '../../assets/images/twitter.svg';
import linkedin_circled from '../../assets/images/linkedin.svg';
import copySvg from '../../assets/images/copy-svg.svg';

import copy from 'copy-to-clipboard';
import toast from 'react-hot-toast';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';

const ShareModel = ({ show, handleClose, modelDetails }) => {
  const copyURL = () => {
    var data = {
      domain: 'esaijobs.com',
      originalURL: !modelDetails.posId
        ? window.location.href
        : window.location.origin + '/jobdetail/' + modelDetails.posId,
    };

    return fetch('https://api.short.io/links/public', {
      method: 'post',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: 'pk_AkRowlwdDyx9ikA4',
      },
      body: JSON.stringify(data),
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (data) {
        return data.shortURL;
      });
  };

  const copyLinkHandler = async () => {
    const link = await copyURL();

    copy(link || window.location.href);
    toast.success('Copied link!');
  };

  return (
    <Modal
      className='modal-md'
      show={show}
      onHide={handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Body>
        <div className='share-modal'>
          <div className='job-detail-block'>
            <div className='job-header'>
              <div className='job-title'>
                <div className='job-logo job-logo-detail'>
                  <img alt='logo' src={modelDetails?.logo} />
                </div>
                <div className='title-desc'>
                  <h3>{modelDetails?.name}</h3>
                  <ul className='m-0 p-0'>
                    <li>{modelDetails?.industry} </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <hr className='mt-xxl-4 mb-xxl-4 mb-3 mt-3' />
          <p className='text-center'>Share this job</p>
          <div className='text-center'>
            <ul className='social_link'>
              <li className=''>
                <FacebookShareButton
                  url={
                    !modelDetails.posId
                      ? window.location.href
                      : window.location.origin +
                        '/jobdetail/' +
                        modelDetails.posId
                  }
                >
                  <div className='social-img'>
                    <img src={social_fb} alt='fb' />
                  </div>
                </FacebookShareButton>
              </li>
              <li>
                <TwitterShareButton
                  url={
                    !modelDetails.posId
                      ? window.location.href
                      : window.location.origin +
                        '/jobdetail/' +
                        modelDetails.posId
                  }
                >
                  <div className='social-img'>
                    <img src={social_twitter} alt='twitter' />
                  </div>
                </TwitterShareButton>
              </li>
              <li>
                <LinkedinShareButton
                  url={
                    !modelDetails.posId
                      ? window.location.href
                      : window.location.origin +
                        '/jobdetail/' +
                        modelDetails.posId
                  }
                >
                  <div className='social-img'>
                    <img src={linkedin_circled} alt='linkedin' />
                  </div>
                </LinkedinShareButton>
              </li>
              <li>
                <div className='social-img' onClick={() => copyLinkHandler()}>
                  <img src={copySvg} alt='linkedin' />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareModel;
